import { NumberInput } from "components/atoms/NumberInput";
import { PanelBody } from "components/atoms/Panel/PanelBody";
import { FormControl } from "components/molecules/FormControl";
import { PackageTypeSelect } from "components/molecules/PackageTypeSelect";
import {
  DEFAULT_MIN_HEIGHT_CM,
  DEFAULT_DIMENSIONS_UNIT,
  DEFAULT_WEIGHT_UNIT,
} from "constants/misc";
import {
  DimensionUnits,
  DimensionUnitSettings,
} from "interfaces/dimension-units.type";
import { WeightUnits, WeightUnitSettings } from "interfaces/weight-units.type";
import { IShipmentPackageGrouped } from "models/shipmentPackage/shipmentPackage.model";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import {
  CreateShipmentContents,
  CreateShipmentItemErrors,
} from "store/createShipmentSlice/createShipmentSlice.types";
import {
  convertDatabaseToValue,
  convertGramsToKilos,
  convertKilosToGrams,
  convertValueToDatabase,
} from "utils/convertGramsToKilos";
import { convertCmToInches } from "utils/convertInchesToCm";
import {
  convertKilosToPounds,
  convertPoundsToKilos,
} from "utils/convertPoundsToKilos";
import { isPackageRowValid } from "./utils/CreateShipmentFormPackages.utils";
import { CreateShipmentFormPackagesHeader } from "./CreateShipmentFormPackagesHeader";
import { CreateShipmentFormPackagesRowErrors } from "./CreateShipmentFormPackagesRowErrors";
import { PackagesTestIds } from "./CreateShipmentFormPackagesTestIds.enum";
import { CreateShipmentFormContentsRow } from "../../CreateShipmentForm/CreateShipmentFormContents/CreateShipmentFormContentsRow";
import { ShipmentContentsFieldNames } from "enum/shipment-field-names.enum";
import {
  SET_DEFAULT_PACKAGE_DIMENSIONS,
  addContentsRow,
  onChangeContentsField,
  onTouchContentsField,
  removeContentsRow,
  setDefaultItemValueInitialState,
} from "store/createShipmentSlice/index.slice";
import { Divider } from "components/atoms/Divider";
import { Stack } from "components/atoms/Stack";
import { CreateShipmentFormContentsAddRow } from "../../CreateShipmentForm/CreateShipmentFormContents/CreateShipmentFormContentsAddRow";
import { useEffect } from "react";
import { LithiumCategorySelect } from "components/molecules/LithiumCategorySelect";
import { ClassSelect } from "components/molecules/ClassSelect";
import { PackingGroupSelect } from "components/molecules/PackingGroupSelect";
import { Switch } from "components/atoms/Switch";
import { PanelHeader } from "components/atoms/Panel/PanelHeader";
import { PanelTitle } from "components/atoms/Panel/PanelTitle";

export type IPackageRowProps = {
  showContents: boolean;
  number: number;
  touched: { [key in keyof IShipmentPackageGrouped]: boolean };
  errors: CreateShipmentItemErrors;
  showErrors: boolean;
  item: IShipmentPackageGrouped;
  onTouchQuantity(value: boolean): void;
  onTouchLength(value: boolean): void;
  onTouchHeight(value: boolean): void;
  onTouchWidth(value: boolean): void;
  onTouchWeight(value: boolean): void;
  onRemove(number: number): void;
  onChangeQuantity(value: IShipmentPackageGrouped["quantity"] | null): void;
  onChangeType(value: IShipmentPackageGrouped["type"] | null): void;
  onChangeLength(value: IShipmentPackageGrouped["length"] | null): void;
  onChangeHeight(value: IShipmentPackageGrouped["height"] | null): void;
  onChangeWidth(value: IShipmentPackageGrouped["width"] | null): void;
  onChangeWeight(value: IShipmentPackageGrouped["weight"] | null): void;
  onChangeClass(value: IShipmentPackageGrouped["class"] | null): void;
  onChangePackingGroup(
    value: IShipmentPackageGrouped["packingGroup"] | null
  ): void;
  onChangeDGNetWeight(
    value: IShipmentPackageGrouped["dgNetWeight"] | null
  ): void;
  onChangeDGVolume(
    value: IShipmentPackageGrouped["dgVolumeMl100"] | null
  ): void;
  onChangeDryIceWeight(
    value: IShipmentPackageGrouped["dryIceWeight"] | null
  ): void;
  onChangeLithiumCategory(
    value: IShipmentPackageGrouped["lithiumCategory"] | null
  ): void;
  onChangeDGUNNumber(value: IShipmentPackageGrouped["dgunNumber"] | null): void;
  onChangeDGPackage: () => void;
};

export const PackageRow = (props: IPackageRowProps) => {
  const {
    showContents,
    number,
    item,
    errors,
    onRemove,
    onChangeQuantity,
    onChangeHeight,
    onChangeLength,
    onChangeType,
    onChangeWidth,
    onChangeWeight,
    onTouchQuantity,
    onTouchHeight,
    onTouchLength,
    onTouchWeight,
    onTouchWidth,
    onChangeClass,
    onChangePackingGroup,
    onChangeDGNetWeight,
    onChangeDGVolume,
    onChangeDryIceWeight,
    onChangeLithiumCategory,
    onChangeDGUNNumber,
    onChangeDGPackage,
    showErrors,
  } = props;

  const dispatch = useAppDispatch();

  const shipment = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });

  useEffect(() => {
    // update the first item default settings
    if (!shipment.editingShipmentUUID) {
      dispatch(setDefaultItemValueInitialState({ defaultSettings: settings }));
      dispatch(SET_DEFAULT_PACKAGE_DIMENSIONS({ defaultSettings: settings }));
    }
  }, []);

  const contents = shipment.values.contents.filter(
    (contents) => contents.packageId === number
  );

  const firstItemForPackage = shipment.values.contents.findIndex((contents) => {
    return contents.packageId === number;
  });

  const getContentsRowIndex = (index) => {
    return firstItemForPackage + index;
  };
  const { financialCompany } = useSelector((state: RootState) => {
    return state.authSlice;
  });
  const handleTouchField = (
    index: number,
    field: keyof CreateShipmentContents
  ) => {
    dispatch(
      onTouchContentsField({
        id: getContentsRowIndex(index),
        key: field,
      })
    );
  };

  const handleChangeField = (
    index: number,
    value: number | string,
    field: keyof CreateShipmentContents
  ) => {
    dispatch(
      onChangeContentsField({
        id: getContentsRowIndex(index),
        value,
        field,
      })
    );
  };

  const handleAddContentsRow = () => {
    dispatch(addContentsRow({ packageId: number, defaultSettings: settings }));
  };

  const handleRemoveContentsRow = (contentRowIndex: number) => {
    dispatch(removeContentsRow(getContentsRowIndex(contentRowIndex)));
  };

  const shouldDivideTotalPackageWeightByQuantityInUi =
    settings?.divideTotalPackageWeightByQuantityInUi;

  const weightInputTitle = shouldDivideTotalPackageWeightByQuantityInUi
    ? "Total Weight"
    : "Weight";
  const requiresVolume =
    item.class === "2.1" ||
    item.class === "2.2" ||
    item.class === "2.3" ||
    item.class === "3";

  return (
    <div data-testid={`${PackagesTestIds.PACKAGES_ROW}_${number}`}>
      <CreateShipmentFormPackagesHeader onRemove={onRemove} number={number} />

      <PanelBody>
        {!isPackageRowValid(errors) && showErrors && (
          <CreateShipmentFormPackagesRowErrors
            errors={errors}
            number={number}
          />
        )}
        <div className="grid grid-cols-3 gap-3 lg:grid-cols-6">
          <FormControl label="Quantity">
            <NumberInput
              min={1}
              step={1}
              precision={0}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                e.target.select();
              }}
              value={item.quantity}
              onBlur={() => onTouchQuantity(true)}
              onChange={onChangeQuantity}
              placeholder={"0"}
              testId={`${PackagesTestIds.PACKAGES_QUANTITY_FIELD}_${number}`}
            />
          </FormControl>
          <FormControl label="Package type">
            <PackageTypeSelect
              value={item.type}
              isDisabled={number > 0}
              onChange={(option) => onChangeType(option.value)}
            />
          </FormControl>
          {financialCompany == 8 && (
            <>
              <FormControl
                label={`${weightInputTitle} (${
                  settings?.weightUnit
                    ? WeightUnitSettings[settings?.weightUnit]?.label
                    : WeightUnitSettings[DEFAULT_WEIGHT_UNIT].label
                })`}
                isRequired
              >
                <NumberInput
                  value={convertDatabaseToValue(
                    settings?.weightUnit === WeightUnits.pound
                      ? convertKilosToPounds(convertGramsToKilos(item.weight))
                      : convertGramsToKilos(item.weight)
                  )}
                  // min={
                  //   settings?.weightUnit === WeightUnits.pound
                  //     ? convertKilosToPounds(
                  //         convertGramsToKilos(DEFAULT_MIN_WEIGHT_GRAMS)
                  //       )
                  //     : convertGramsToKilos(DEFAULT_MIN_WEIGHT_GRAMS)
                  // }
                  onChange={onChangeWeight}
                  placeholder={"0"}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    e.target.select();
                  }}
                  onBlur={() => onTouchWeight(true)}
                  testId={`${PackagesTestIds.PACKAGES_WEIGHT_FIELD}_${number}`}
                />
              </FormControl>
            </>
          )}
          <FormControl
            label={`Length (${
              settings?.dimensionsUnit
                ? DimensionUnitSettings[settings?.dimensionsUnit]?.label
                : DimensionUnitSettings[DEFAULT_DIMENSIONS_UNIT].label
            })`}
            isRequired
          >
            <NumberInput
              value={convertDatabaseToValue(
                settings?.dimensionsUnit === DimensionUnits.inch
                  ? convertCmToInches(item.length)
                  : item.length
              )}
              // min={convertDatabaseToValue(DEFAULT_MIN_LENGTH_CM)}
              onChange={onChangeLength}
              placeholder={"0"}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                e.target.select();
              }}
              onBlur={() => onTouchLength(true)}
              testId={`${PackagesTestIds.PACKAGES_LENGTH_FIELD}_${number}`}
            />
          </FormControl>
          <FormControl
            label={`Width (${
              settings?.dimensionsUnit
                ? DimensionUnitSettings[settings?.dimensionsUnit]?.label
                : DimensionUnitSettings[DEFAULT_DIMENSIONS_UNIT].label
            })`}
            isRequired
          >
            <NumberInput
              value={convertDatabaseToValue(
                settings?.dimensionsUnit === DimensionUnits.inch
                  ? convertCmToInches(item.width)
                  : item.width
              )}
              // min={convertDatabaseToValue(DEFAULT_MIN_WIDTH_CM)}
              onChange={onChangeWidth}
              placeholder={"0"}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                e.target.select();
              }}
              onBlur={() => onTouchWidth(true)}
              testId={`${PackagesTestIds.PACKAGES_WIDTH_FIELD}_${number}`}
            />
          </FormControl>
          <FormControl
            label={`Height (${
              settings?.dimensionsUnit
                ? DimensionUnitSettings[settings?.dimensionsUnit]?.label
                : DimensionUnitSettings[DEFAULT_DIMENSIONS_UNIT].label
            })`}
            isRequired
          >
            <NumberInput
              value={convertDatabaseToValue(
                settings?.dimensionsUnit === DimensionUnits.inch
                  ? convertCmToInches(item.height)
                  : item.height
              )}
              min={convertDatabaseToValue(DEFAULT_MIN_HEIGHT_CM)}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                e.target.select();
              }}
              onBlur={() => onTouchHeight(true)}
              onChange={onChangeHeight}
              placeholder={"0"}
              testId={`${PackagesTestIds.PACKAGES_HEIGHT_FIELD}_${number}`}
            />
          </FormControl>
          {financialCompany != 8 && (
            <>
              <FormControl
                label={`${weightInputTitle} (${
                  settings?.weightUnit
                    ? WeightUnitSettings[settings?.weightUnit]?.label
                    : WeightUnitSettings[DEFAULT_WEIGHT_UNIT].label
                })`}
                isRequired
              >
                <NumberInput
                  value={convertDatabaseToValue(
                    settings?.weightUnit === WeightUnits.pound
                      ? convertKilosToPounds(convertGramsToKilos(item.weight))
                      : convertGramsToKilos(item.weight)
                  )}
                  // min={
                  //   settings?.weightUnit === WeightUnits.pound
                  //     ? convertKilosToPounds(
                  //         convertGramsToKilos(DEFAULT_MIN_WEIGHT_GRAMS)
                  //       )
                  //     : convertGramsToKilos(DEFAULT_MIN_WEIGHT_GRAMS)
                  // }
                  onChange={onChangeWeight}
                  placeholder={"0"}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    e.target.select();
                  }}
                  onBlur={() => onTouchWeight(true)}
                  testId={`${PackagesTestIds.PACKAGES_WEIGHT_FIELD}_${number}`}
                />
              </FormControl>
            </>
          )}
        </div>
      </PanelBody>
      {shipment.dangerousGoodsOption && (
        <>
          <Divider />
          <PanelHeader className="flex flex-row items-center justify-between mb-4">
            <PanelTitle>Dangerous Goods Details</PanelTitle>
            <div className="flex flex-row">
              <div>Package Restricted </div>

              <div className="ml-4 item-center">
                <Switch
                  checked={item.dgPackage}
                  disabled={false}
                  onCheckedChange={() => onChangeDGPackage()}
                />
              </div>
            </div>
          </PanelHeader>
          {item.dgPackage ? (
            <PanelBody>
              <div className="grid grid-cols-3 gap-3 lg:grid-cols-5">
                {shipment.dangerousGoodsOption.value === 0 && (
                  <>
                    <FormControl
                      label={`Class`}
                      isRequired={item.dgPackage}
                      isDisabled={!item.dgPackage}
                    >
                      <ClassSelect
                        value={item.class}
                        onChange={(value) => onChangeClass(value.label)}
                        isDisabled={!item.dgPackage}
                      />
                    </FormControl>
                    <FormControl
                      label={`Packing Group`}
                      isRequired={item.dgPackage}
                      isDisabled={!item.dgPackage}
                    >
                      <PackingGroupSelect
                        value={item.packingGroup}
                        onChange={(value) => onChangePackingGroup(value.label)}
                        isDisabled={!item.dgPackage}
                      />
                    </FormControl>
                    <FormControl
                      label={`DG Net Weight (${
                        settings?.weightUnit
                          ? WeightUnitSettings[settings?.weightUnit]?.label
                          : WeightUnitSettings[DEFAULT_WEIGHT_UNIT].label
                      })`}
                      isRequired={item.dgPackage}
                      isDisabled={!item.dgPackage}
                    >
                      <NumberInput
                        value={convertDatabaseToValue(
                          settings?.weightUnit === WeightUnits.pound
                            ? convertKilosToPounds(
                                convertGramsToKilos(item.dgNetWeight)
                              )
                            : convertGramsToKilos(item.dgNetWeight)
                        )}
                        onChange={onChangeDGNetWeight}
                        disabled={!item.dgPackage}
                      />
                    </FormControl>
                    {requiresVolume ? (
                      <FormControl
                        label="Volume (L)"
                        isRequired={requiresVolume}
                        isDisabled={!requiresVolume}
                      >
                        <NumberInput
                          value={convertDatabaseToValue(
                            convertGramsToKilos(item.dgVolumeMl100)
                          )}
                          onChange={onChangeDGVolume}
                          disabled={!requiresVolume}
                        />
                      </FormControl>
                    ) : null}
                  </>
                )}
                {shipment.dangerousGoodsOption.value === 1 && (
                  <>
                    <FormControl
                      label={`Dry Ice Weight (${
                        settings?.weightUnit
                          ? WeightUnitSettings[settings?.weightUnit]?.label
                          : WeightUnitSettings[DEFAULT_WEIGHT_UNIT].label
                      })`}
                      isRequired={item.dgPackage}
                      isDisabled={!item.dgPackage}
                    >
                      <NumberInput
                        value={convertDatabaseToValue(
                          settings?.weightUnit === WeightUnits.pound
                            ? convertKilosToPounds(
                                convertGramsToKilos(item.dryIceWeight)
                              )
                            : convertGramsToKilos(item.dryIceWeight)
                        )}
                        onChange={onChangeDryIceWeight}
                        disabled={!item.dgPackage}
                      />
                    </FormControl>
                  </>
                )}
                {shipment.dangerousGoodsOption.value === 2 && (
                  <>
                    <FormControl
                      label={`Lithium Category`}
                      isRequired={item.dgPackage}
                      isDisabled={!item.dgPackage}
                    >
                      <LithiumCategorySelect
                        value={item.lithiumCategory}
                        onChange={(value) =>
                          onChangeLithiumCategory(value.label)
                        }
                        isDisabled={!item.dgPackage}
                      />
                    </FormControl>
                  </>
                )}
                {shipment.dangerousGoodsOption.value === 0 && (
                  <>
                    <FormControl
                      label="DG/UN Number"
                      isRequired={item.dgPackage}
                      isDisabled={!item.dgPackage}
                    >
                      <NumberInput
                        precision={0}
                        value={item.dgunNumber}
                        onChange={onChangeDGUNNumber}
                        disabled={!item.dgPackage}
                      />
                    </FormControl>
                  </>
                )}
              </div>
            </PanelBody>
          ) : null}
        </>
      )}
      {showContents && (
        <>
          <Divider />
          <PanelBody>
            <Stack divider={<Divider />} spacing={6}>
              {contents.map((content, index) => {
                return (
                  <CreateShipmentFormContentsRow
                    showErrors={shipment.submitCount > 0}
                    touched={
                      shipment.touched.contents[getContentsRowIndex(index)]
                    }
                    errors={
                      shipment.errors.contents[getContentsRowIndex(index)]
                    }
                    item={content}
                    number={index}
                    key={index}
                    onRemove={() => {
                      handleRemoveContentsRow(index);
                    }}
                    onTouchCommodityCode={() => {
                      handleTouchField(
                        index,
                        ShipmentContentsFieldNames.commodityCode
                      );
                    }}
                    onTouchCurrency={() => {
                      handleTouchField(
                        index,
                        ShipmentContentsFieldNames.currency
                      );
                    }}
                    onTouchItemValue={() => {
                      handleTouchField(
                        index,
                        ShipmentContentsFieldNames.itemValue
                      );
                    }}
                    onTouchItemWeight={() => {
                      handleTouchField(
                        index,
                        ShipmentContentsFieldNames.weight
                      );
                    }}
                    onTouchSKU={() => {
                      handleTouchField(index, ShipmentContentsFieldNames.sku);
                    }}
                    onTouchQuantity={() => {
                      handleTouchField(
                        index,
                        ShipmentContentsFieldNames.quantity
                      );
                    }}
                    onTouchItemDescription={() => {
                      handleTouchField(
                        index,
                        ShipmentContentsFieldNames.description
                      );
                    }}
                    onTouchManufacturerDetails={() => {
                      handleTouchField(
                        index,
                        ShipmentContentsFieldNames.manufacturerDetails
                      );
                    }}
                    onTouchOriginCountry={() => {
                      handleTouchField(
                        index,
                        ShipmentContentsFieldNames.countryOfOrigin
                      );
                    }}
                    onChangeCurrency={(value) => {
                      handleChangeField(
                        index,
                        value,
                        ShipmentContentsFieldNames.currency
                      );
                    }}
                    onChangeItemDescription={(value) => {
                      handleChangeField(
                        index,
                        value,
                        ShipmentContentsFieldNames.description
                      );
                    }}
                    onChangeItemValue={(value) => {
                      handleChangeField(
                        index,
                        convertValueToDatabase(value),
                        ShipmentContentsFieldNames.itemValue
                      );
                    }}
                    onChangeCommodityCode={(value) => {
                      handleChangeField(
                        index,
                        value,
                        ShipmentContentsFieldNames.commodityCode
                      );
                    }}
                    onChangeItemWeight={(value) => {
                      handleChangeField(
                        index,
                        convertValueToDatabase(
                          settings?.weightUnit === WeightUnits.pound
                            ? convertKilosToGrams(convertPoundsToKilos(value))
                            : convertKilosToGrams(value)
                        ),
                        ShipmentContentsFieldNames.weight
                      );
                    }}
                    onChangeOriginCountry={(value) => {
                      handleChangeField(
                        index,
                        value,
                        ShipmentContentsFieldNames.countryOfOrigin
                      );
                    }}
                    onChangeManufacturerDetails={(value) => {
                      handleChangeField(
                        index,
                        value,
                        ShipmentContentsFieldNames.manufacturerDetails
                      );
                    }}
                    onChangeQuantity={(value) => {
                      handleChangeField(
                        index,
                        value,
                        ShipmentContentsFieldNames.quantity
                      );
                    }}
                    onChangeSKU={(value) => {
                      handleChangeField(
                        index,
                        value,
                        ShipmentContentsFieldNames.sku
                      );
                    }}
                  />
                );
              })}
            </Stack>
          </PanelBody>
        </>
      )}
      {showContents ? (
        <CreateShipmentFormContentsAddRow onClick={handleAddContentsRow} />
      ) : (
        <></>
      )}
    </div>
  );
};
