import { DEFAULT_META } from "constants/misc";
import { ShipmentStatusCategories } from "constants/shipment-statuses.config";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { SortDirection } from "interfaces/api.interface";
import { IShipment } from "models/shipment/shipment.model";
import { IShipmentsSlice } from "./ShipmentsSlice.interface";

const fromDate = new Date();

const toDate = new Date();

export const ShipmentsInitialState: IShipmentsSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  loadingRerunRules: ApiLoadingStatus.IDLE,
  selectedShipmentIds: [],
  currentShipmentLoadingStatus: ApiLoadingStatus.IDLE,
  currentShipment: {} as IShipment,
  showMore: false,
  items: [],
  query: {
    offset: 0,
    filters: {
      statuses: ShipmentStatusCategories.READY,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
      trackingStatus: null,
    },
    sort: {
      createdDate: SortDirection.DESC,
    },
  },
  meta: {
    ...DEFAULT_META,
    statusCount: {
      complete: 0,
      error: 0,
      ready: 0,
      cancelled: 0,
    },
    shipmentIds: [],
  },
  reportShipmentFrom: fromDate.toISOString(),
  reportShipmentTo: toDate.toISOString(),
  listOfTrackingStatuses: [],
  allIntegrationStores: [],
  dateRangeDays: "1d",
};
