import { ShipmentContentsFieldNames } from "./shipment-field-names.enum";

export enum BulkUploadColumnName {
  "warehouseName" = "warehouseName",
  "customerReference" = "customerReference",
  "reasonForExport" = "reasonForExport",
  iossNumber = "iossNumber",
  invoiceDate = "invoiceDate",
  invoiceNumber = "invoiceNumber",
  orderNumber = "orderNumber",
  "fromAddressFirstName" = "fromAddressFirstName",
  "fromAddressLastName" = "fromAddressLastName",
  "fromAddressCompany" = "fromAddressCompany",
  "fromAddressPhone" = "fromAddressPhone",
  "fromAddressEmail" = "fromAddressEmail",
  "fromAddressStreet1" = "fromAddressStreet1",
  "fromAddressStreet2" = "fromAddressStreet2",
  "fromAddressStreet3" = "fromAddressStreet3",
  "fromAddressCity" = "fromAddressCity",
  "fromAddressCountyState" = "fromAddressCountyState",
  "fromAddressZip" = "fromAddressZip",
  "fromAddressCountryIso" = "fromAddressCountryIso",
  "fromAddressEoriNumber" = "fromAddressEoriNumber",
  "fromAddressVatNumber" = "fromAddressVatNumber",
  "toAddressFirstName" = "toAddressFirstName",
  "toAddressLastName" = "toAddressLastName",
  "toAddressCompany" = "toAddressCompany",
  "toAddressPhone" = "toAddressPhone",
  "toAddressEmail" = "toAddressEmail",
  "toAddressStreet1" = "toAddressStreet1",
  "toAddressStreet2" = "toAddressStreet2",
  "toAddressStreet3" = "toAddressStreet3",
  "toAddressCity" = "toAddressCity",
  "toAddressCountyState" = "toAddressCountyState",
  "toAddressZip" = "toAddressZip",
  "toAddressCountryIso" = "toAddressCountryIso",
  "toAddressEoriNumber" = "toAddressEoriNumber",
  "toAddressVatNumber" = "toAddressVatNumber",
  "customerReference2" = "customerReference2",
  "customShipmentData" = "customShipmentData",
  "dangerousGoodsClass" = "dangerousGoodsClass",
  "dangerousGoodsPackingGroup" = "dangerousGoodsPackingGroup",
  "dangerousGoodsNetWeight" = "dangerousGoodsNetWeight",
  "unNumber" = "unNumber",
  "dryIceWeight" = "dryIceWeight",
  "lithiumCategory" = "lithiumCategory",
  "dangerousGoodsVolume" = "dangerousGoodsVolume",
}

export enum DangerousGoodsCategory {
  "DGR | Dangerous Goods" = "DGR | Dangerous Goods",
  "DRY_ICE | Dry Ice" = "DRY_ICE | Dry Ice",
  "LITHIUM | Lithium Battery" = "LITHIUM | Lithium Battery",
}

export enum LithiumCategory {
  "SII-966" = "SII-966",
  "SII-967" = "SII-967",
  "SII-969" = "SII-969",
  "SII-970" = "SII-970",
  "SI-966" = "SI-966",
  "SI-969" = "SI-969",
  "SI-967" = "SI-967",
  "SI-970" = "SI-970",
  "SIA-965" = "SIA-965",
  "SIB-965" = "SIB-965",
  "SIA-968" = "SIA-968",
  "SIB-968" = "SIB-968",
}

export enum Class {
  "Division 1.1" = "1.1",
  "Division 1.2" = "1.2",
  "Division 1.3" = "1.3",
  "Division 1.4" = "1.4",
  "Division 1.5" = "1.5",
  "Division 1.6" = "1.6",
  "Division 2.1" = "2.1",
  "Division 2.2" = "2.2",
  "Division 2.3" = "2.3",
  "Division 3" = "3",
  "Division 4.1" = "4.1",
  "Division 4.2" = "4.2",
  "Division 4.3" = "4.3",
  "Division 5.1" = "5.1",
  "Division 5.2" = "5.2",
  "Division 6.1" = "6.1",
  "Division 6.2" = "6.2",
  "Division 7" = "7",
  "Division 8" = "8",
  "Division 9" = "9",
}

export enum PackingGroup {
  "I" = "I",
  "II" = "II",
  "III" = "III",
}

export enum BulkUploadPackageFieldNames {
  "packageType" = "packageType",
  "packageWidth" = "packageWidth",
  "packageHeight" = "packageHeight",
  "packageLength" = "packageLength",
  "packageWeight" = "packageWeight",
}

export enum PackagesExtra {
  NumberOfPackages = "numberOfPackages",
}

export enum BulkUploadItemFieldNames {
  "itemDescription" = "itemDescription",
  "itemOriginCountryIso" = "itemOriginCountryIso",
  "itemQuantity" = "itemQuantity",
  "itemValue" = "itemValue",
  "itemValueCurrency" = "itemValueCurrency",
  "itemSku" = "itemSku",
  "itemHsCode" = "itemHsCode",
  "itemWeight" = "itemWeight",
  "manufacturerDetails" = "manufacturerDetails",
  "shippingMethod" = "shippingMethod",
  "totalShipmentItemQuantity" = "totalShipmentItemQuantity",
}

export const ShipmentConsignmentItemErrorsMapping: {
  [key in BulkUploadItemFieldNames]: ShipmentContentsFieldNames;
} = {
  [BulkUploadItemFieldNames.itemDescription]:
    ShipmentContentsFieldNames.description,
  [BulkUploadItemFieldNames.itemOriginCountryIso]:
    ShipmentContentsFieldNames.countryOfOrigin,
  [BulkUploadItemFieldNames.itemQuantity]: ShipmentContentsFieldNames.quantity,
  [BulkUploadItemFieldNames.itemValue]: ShipmentContentsFieldNames.itemValue,
  [BulkUploadItemFieldNames.itemValueCurrency]:
    ShipmentContentsFieldNames.currency,
  [BulkUploadItemFieldNames.itemSku]: ShipmentContentsFieldNames.sku,
  [BulkUploadItemFieldNames.itemHsCode]:
    ShipmentContentsFieldNames.commodityCode,
  [BulkUploadItemFieldNames.itemWeight]: ShipmentContentsFieldNames.weight,
  [BulkUploadItemFieldNames.manufacturerDetails]:
    ShipmentContentsFieldNames.manufacturerDetails,
  [BulkUploadItemFieldNames.shippingMethod]:
    ShipmentContentsFieldNames.shippingMethod,
  [BulkUploadItemFieldNames.totalShipmentItemQuantity]:
    ShipmentContentsFieldNames.totalShipmentItemQuantity,
};
