import { Model } from "../types/model.type";
import { apiPaths } from "enum/paths.enum";
import { ShipmentColumns } from "./shipment.columns";
import { ICarrier } from "models/carrier/carrier.model";
import { IService } from "models/service/service.model";
import { ITariff } from "models/tariff/tariff.model";
import { ISupplier } from "models/supplier/supplier.model";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { IUser } from "models/user/user.model";
import { IQuotedRate } from "models/quote/quote.model";
import { IBulkShipment } from "models/bulkShipment/bulkShipment.model";
import { statuses } from "enum/shipment-statuses.enum";
import { ShipmentDutyOrTaxPaidBy } from "enum/shipment-duty-or-tax-paid-by.enum";
import { ShipmentExportReasons } from "enum/shipment-export-reasons.enum";
import { ShipmentContentsFieldNames } from "enum/shipment-field-names.enum";
import { ILabelJob } from "models/labelJob/labelJob.model";
import { CarrierErrorCodes } from "enum/error-codes/carrier-error-codes.enum";
import { ZoneErrorCodes } from "enum/error-codes/zone-error-codes.enum";
import { RuleErrorCodes } from "enum/error-codes/rule-error-codes.enum";
import { ShipmentStatusCategoryStrings } from "enum/shipment-status-category-string.enum";
import { IShipmentPackage } from "models/shipmentPackage/shipmentPackage.model";
import { ThemeColorPalettes } from "theme/theme-colors.theme";
import { ShipmentTypes } from "enum/shipment-types.enum";
import { BulkUploadObject } from "interfaces/bulk-upload-object.interface";
import { GenericErrorCodes } from "enum/error-codes/generic-error-codes.enum";
import { ShipmentConsignmentErrorCodes } from "enum/error-codes/shipment-consignment-error-codes.enum";
import { ShipmentItemErrorCodes } from "enum/error-codes/shipment-item-error-codes.enum";
import {
  FoundDropOffLocations,
  DropoffObjectType,
} from "store/createShipmentSlice/createShipmentSlice.types";

export type BulkUploadObjectErrors = {
  [key in keyof BulkUploadObject]?:
    | GenericErrorCodes
    | ShipmentConsignmentErrorCodes
    | ShipmentItemErrorCodes;
};

export type ShipmentError = {
  code: string;
  props: any;
};

export type PackageError = {
  errors: {
    limits: ShipmentError[];
    packageHeight: ShipmentError[];
    packageLength: ShipmentError[];
    packageWeight: ShipmentError[];
    packageWidth: ShipmentError[];
  };
  isValid: boolean;
  packageItems: {
    errors: { [key: string]: ShipmentError };
    isValid: boolean;
  }[];
};

export type CarrierError = {
  code: CarrierErrorCodes;
};

export type ZoneError = {
  code: ZoneErrorCodes;
};

export type RuleError = {
  code: RuleErrorCodes;
};

export type ShipmentErrors = {
  consignment: BulkUploadObjectErrors;
  rule: RuleError[];
  packages: PackageError[];
  carrier: CarrierError[];
  zone: ZoneError[];
};
export type TrackingEvent = {
  statusCode: string;
  statusTime: string;
  trackingEvent: string;
};
export type ValidBarcodes = {
  barcode: string;
  events: TrackingEvent[];
};

export type ITrackingData = {
  invalidBarcodes: string[];
  validBarcodes: ValidBarcodes[];
};

export const ShipmentStatusCategorySettings: {
  [key in ShipmentStatusCategoryStrings]: {
    color: ThemeColorPalettes;
  };
} = {
  [ShipmentStatusCategoryStrings.READY]: {
    color: "orange",
  },
  [ShipmentStatusCategoryStrings.TO_FIX]: {
    color: "red",
  },
  [ShipmentStatusCategoryStrings.COMPLETE]: {
    color: "green",
  },
  [ShipmentStatusCategoryStrings.CANCELLED]: {
    color: "gray",
  },
};

export const ShipmentStatusSettings: {
  [key in statuses]: {
    color: ThemeColorPalettes;
    label: string;
  };
} = {
  [statuses.error]: {
    color: "red",
    label: "Error",
  },
  [statuses.ready_for_print]: {
    color: "orange",
    label: "Ready for print",
  },
  [statuses.label_created]: {
    color: "green",
    label: "Label created",
  },
  [statuses.cancelled]: {
    color: "blue",
    label: "Cancelled",
  },
};

export type IShipmentItemDescription = {
  [ShipmentContentsFieldNames.shipmentPackageIndex]: number;
  [ShipmentContentsFieldNames.description]: string;
  [ShipmentContentsFieldNames.commodityCode]: string;
  [ShipmentContentsFieldNames.sku]: string;
  [ShipmentContentsFieldNames.quantity]: number;
  [ShipmentContentsFieldNames.itemValue]: number;
  [ShipmentContentsFieldNames.currency]: string;
  [ShipmentContentsFieldNames.weight]: number;
  [ShipmentContentsFieldNames.countryOfOrigin]: string;
  [ShipmentContentsFieldNames.manufacturerDetails]: string;
  [ShipmentContentsFieldNames.shippingMethod]: string;
  [ShipmentContentsFieldNames.shipmentId]?: string;
};

export interface IShipment {
  id: string;
  uuid: string;
  salesAccountId: ISalesAccount["id"];
  shipmentBulkUploadId: IBulkShipment["id"] | null;
  createdBy: IUser["id"];
  createdByUser?: Pick<IUser, "id" | "email">;
  jobNumber: string;
  status: statuses;
  createdDate: string;
  customerReference: string;
  customerReference2: string;
  invoiceNumber: string;
  iossNumber: string;
  carrier: ICarrier | null;
  service: IService | null;
  tariff: ITariff | null;
  supplier: ISupplier | null;
  fromAddressFirstName: string;
  fromAddressLastName: string;
  fromAddressEmail: string;
  fromAddressEoriNumber: string;
  fromAddressPhone: string;
  fromAddressCompany: string;
  fromAddressStreet1: string;
  fromAddressStreet2: string;
  fromAddressStreet3: string;
  fromAddressCity: string;
  fromAddressCountyState: string;
  fromAddressVatNumber: string;
  fromAddressZip: string;
  fromAddressCountryIso: string;
  toAddressFirstName: string;
  toAddressLastName: string;
  toAddressEmail: string;
  toAddressEoriNumber: string;
  toAddressPhone: string;
  toAddressCompany: string;
  toAddressStreet1: string;
  toAddressStreet2: string;
  toAddressStreet3: string;
  toAddressCity: string;
  toAddressCountyState: string;
  toAddressVatNumber: string;
  toAddressZip: string;
  toAddressCountryIso: string;
  generatedByAdmin: boolean;
  combinedLabelUrl: string | null;
  rates: IQuotedRate[];
  dutyOrTaxPaidBy: ShipmentDutyOrTaxPaidBy;
  reasonForExport: ShipmentExportReasons;
  packages: IShipmentPackage[];
  itemDescriptions: IShipmentItemDescription[];
  errors: ShipmentErrors;
  shipmentType: ShipmentTypes;
  trackingCodes: string[];
  trackingStatus?: string;
  labelJobs: ILabelJob[];
  bulkUpload?: Pick<IBulkShipment, "id" | "uuid" | "filename" | "createdDate">;
  packageTrackingData: ITrackingData;
  pickUpFromDateLocalTime?: string;
  pickUpInstructions?: string;
  pickUpToDateLocalTime?: string;
  shouldPickUp?: boolean;
  shouldDropOff?: boolean;
  orderNumber?: string;
  useInsurance: boolean;
  insuranceAmount: number;
  insuranceAmountCurrencyCode: string;
  upsPickupPrnNumber?: string;
  fedexPickupPrnNumber?: string;
  dropoffInfo?: DropoffObjectType;
  dangerousGoods?: string;
  shipmentSourceName: string;
  estimatedDeliveryDate: string;
  sku: string;
  description: string;
  packageQuantity: string;
  itemQuantity: string;
  shouldSplitDutyAndVat: boolean;
  integrationStoreName: string;
  integrationStoreId?: number;
  shipmentIntegrationId?: number;
  cn22Base64?: string;
  packingSlipBase64?: string;
}

export const ShipmentModel: Model<IShipment> = {
  id: "id",
  displayKey: "id",
  name: "Quote",
  searchItem: () => {},
  path: apiPaths.quotes,
  columns: ShipmentColumns,
  schema: {},
};

interface MatchedAddress {
  city: string;
  stateOrProvinceCode: string;
  postalCode: string;
  countryCode: string;
  residential: boolean;
}

interface MatchedAddressGeoCoord {
  latitude: number;
  longitude: number;
}

interface Distance {
  units: string;
  value: number;
}

interface Contact {
  companyName: string;
  phoneNumber: string;
}

interface Address {
  displayName: string;
  city: string;
  stateOrProvinceCode: string;
  postalCode: string;
  countryCode: string;
  residential: boolean;
}

interface AddressAncillaryDetail {
  displayName: string;
  locationInProperty?: string;
  locationInCity?: string;
  suite?: string;
}

interface ContactAndAddress {
  contact: Contact;
  address: Address;
  addressAncillaryDetail: AddressAncillaryDetail;
}

interface StoreHour {
  dayOfWeek: string;
  operationalHoursType: string;
  operationalHours?: {
    begins: string;
    ends: string;
  };
}

interface LatestDropoffDetail {
  dayOfWeek: string;
  operationTime: string;
}

export interface CarrierDetailList {
  carrierCodeType: string;
  latestDropoffDetails: LatestDropoffDetail[];
  serviceCategory?: string;
}

interface GeoPositionalCoordinates {
  latitude: number;
  longitude: number;
}

interface Weight {
  units: string;
  value: number;
}

interface Dimensions {
  length: number;
  width: number;
  height: number;
  units: string;
}

interface PackageMaximumLimits {
  weight: Weight;
  dimensions: Dimensions;
}

interface LocationCapability {
  carrierCode: string;
  transferOfPossessionType: string;
  serviceCategory?: string;
  daysOfWeek: string[];
}

export interface LocationDetail {
  distance: Distance;
  contactAndAddress: ContactAndAddress;
  locationId: string;
  storeHours: StoreHour[];
  carrierDetailList: CarrierDetailList[];
  geoPositionalCoordinates: GeoPositionalCoordinates;
  locationType: string;
  locationAttributeTypes: string[];
  lockerAvailability: boolean;
  packageMaximumLimits: PackageMaximumLimits;
  locationCapabilities: LocationCapability[];
}

interface Output {
  totalResults: number;
  resultsReturned: number;
  matchedAddress: MatchedAddress;
  matchedAddressGeoCoord: MatchedAddressGeoCoord;
  locationDetailList: LocationDetail[];
  lockerAvailabilityCode: number;
  lockerAvailabilityMessage: string;
}

export interface DropOffLocationsResponse {
  transactionId: string;
  output: Output;
}

export interface LocatorResponse {
  locatorResponse: {
      response: {
          transactionReference: string;
          responseStatusCode: string;
          responseStatusDescription: string;
      };
      allowAllConfidenceLevels: string;
      geocode: {
          latitude: string;
          longitude: string;
      };
      searchResults: {
          dropLocation: DropLocation[];
          mapNavigation: {
              mapDimensions: {
                  height: string;
                  width: string;
              };
              zoomFactor: string;
              mapID: string;
              mapURL: string;
              imageMap: ImageMap[];
          };
      };
  };
}

interface DropLocation {
  locationID: string;
  ivr: {
      phraseID: string;
  };
  geocode: {
      latitude: string;
      longitude: string;
  };
  addressKeyFormat: {
      consigneeName: string;
      addressLine: string;
      politicalDivision2: string;
      politicalDivision1: string;
      postcodePrimaryLow: string;
      postcodeExtendedLow?: string;
      countryCode: string;
  };
  phoneNumber?: string;
  locationAttribute: LocationAttribute[];
  distance: {
      value: string;
      unitOfMeasurement: {
          code: string;
          description: string;
      };
  };
  specialInstructions?: {
      segment: string;
  };
  latestGroundDropOffTime?: string;
  latestAirDropOffTime?: string;
  additionalChargeIndicator?: string;
  displayPhoneNumberIndicator?: string;
  locationNewIndicator?: string;
  willCallLocationIndicator?: string;
  standardHoursOfOperation?: string;
  operatingHours?: {
      standardHours: StandardHours[];
  };
  serviceOfferingList?: {
      serviceOffering: ServiceOffering[];
  };
  accessPointInformation?: {
      publicAccessPointID: string;
      imageURL: string;
      businessClassificationList: {
          businessClassification: BusinessClassification[];
      };
      accessPointStatus: {
          code: string;
          description: string;
      };
  };
}

interface ImageMap {
  locationID: string;
  xCoordinate: string;
  yCoordinate: string;
}

interface LocationAttribute {
  optionType: {
      code: string;
      description: string;
  };
  optionCode: OptionCode;
}

interface OptionCode {
  category?: string;
  code: string;
  description?: string;
  name?: string;
  transportationPickUpSchedule?: {
      pickUp: PickUp[];
  };
}

interface PickUp {
  dayOfWeek: string;
  pickUpDetails: {
      noPickUpIndicator?: string;
      pickUpTime?: string;
  };
}

interface StandardHours {
  hoursType: string;
  dayOfWeek: DayOfWeek[];
}

interface DayOfWeek {
  day: string;
  openHours: string;
  closeHours: string;
}

interface ServiceOffering {
  code: string;
  description: string;
}

interface BusinessClassification {
  code: string;
  description: string;
}
