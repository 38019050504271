import { ModalBody, ModalFooter } from "components/atoms/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeModal } from "store/modalSlice";
import toast from "react-hot-toast";
import { rerunRulesShipments } from "services/api/shipments";
import {
  setSelectedShipmentIds,
  setLoadingRerunRules,
} from "store/shipmentsSlice/index.slice";
import { GENERIC_TOAST_ERROR_MESSAGE } from "constants/strings";
import { Alert } from "components/atoms/Alert";
import { Button } from "components/atoms/Button";
import { fetchShipmentsThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";

export const ConfirmRerunRulesModal = () => {
  const dispatch = useDispatch();

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { selectedShipmentIds, loadingRerunRules } = useSelector(
    (state: RootState) => {
      return state.shipmentsSlice;
    }
  );

  const handleConfirm = async () => {
    dispatch(setLoadingRerunRules(ApiLoadingStatus.LOADING));
    const { data, message } = await rerunRulesShipments(salesAccountUUID, {
      shipmentIds: selectedShipmentIds,
    });
    if (!data.error) {
      dispatch(setLoadingRerunRules(ApiLoadingStatus.SUCCESS));
      dispatch(closeModal({ modalName: "confirmRerunRulesModal" }));
      dispatch(fetchShipmentsThunk());
      dispatch(setSelectedShipmentIds([]));
      dispatch(setLoadingRerunRules(ApiLoadingStatus.IDLE));
      toast.success(message);
    }
    if (data.error) {
      dispatch(setLoadingRerunRules(ApiLoadingStatus.FAILED));
      toast.error(GENERIC_TOAST_ERROR_MESSAGE);
    }
  };

  return (
    <>
      <ModalBody>
        <Alert>Are you sure you want to rerun rules on these shipments?</Alert>
      </ModalBody>
      <ModalFooter>
        <div className="ml-auto">
          <Button
            colorScheme="danger"
            onClick={handleConfirm}
            className="mr-4"
            isLoading={loadingRerunRules === ApiLoadingStatus.LOADING}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              dispatch(closeModal({ modalName: "confirmRerunRulesModal" }));
            }}
            isLoading={loadingRerunRules === ApiLoadingStatus.LOADING}
          >
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
