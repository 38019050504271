import { ConfirmBulkDeleteShipmentsModal } from "../ConfirmBulkDeleteShipmentsModal";
import { CreateBulkShipmentModal } from "../CreateBulkShipmentModal";
import { BulkShipmentProfileWizard } from "../BulkShipmentProfileWizard";
import { CreateShipmentModal } from "../CreateShipmentModal";
import { CreateSingleLabelModal } from "../CreateSingleLabelModal";
import { CreateQuotePalletSwitchWarningModal } from "../CreateQuotePalletSwitchWarningModal";
import { ConfirmDeleteShipmentModal } from "../ConfirmDeleteShipmentModal";
import { SelectReceiverAddressModal } from "../CreateShipmentForm/CreateShipmentForm/SelectReceiverAddressModal";
import { SelectSenderAddressModal } from "../CreateShipmentForm/CreateShipmentForm/SelectSenderAddressModal";
import { LabelsDrawer } from "../LabelsDrawer";
import { ViewShipmentModal } from "../ViewShipmentModal";
import { ViewQuoteModal } from "../ViewQuoteModal";
import { CustomerOnStopWarning } from "../CreateQuoteCustomerOnStopWarning";
import { EditRuleModal } from "../EditRuleModal";
import { CreateCompanyAddressModal } from "../CreateCompanyAddressModal";
import { CreateReceiverAddressModal } from "../CreateReceiverAddressModal";
import { CreateRuleModal } from "../CreateRuleModal";
import { ConfirmRefetchShipmentRates } from "../ConfirmRefetchShipmentRates";
import { EditBulkShipmentProfile } from "../EditBulkShipmentProfile";
import { ConfirmCloseCreateShipmentModal } from "../ConfirmCloseCreateShipmentModal";
import { ConfirmDeleteShipmentProfileModal } from "../ConfirmDeleteShipmentProfileModal";
import { ReportShipmentModal } from "../ReportShipmentModal";
import { ReportQuoteModal } from "../ReportQuoteModal";
import { PickupModal } from "../PickupModal";
import { PickUpRequestModal } from "../PickUpRequestModal";
import { ModalNames } from "store/modalSlice/modalNames.enum";
import { FunctionComponent } from "react";
import { ContactInfoModal } from "../Navigation/NavigationModals/ContactInfo";
import { ViewPickupModal } from "../ViewPickupModal";
import { ConfirmCancelPickupModal } from "../ConfirmCancelPickupModal";
import { ConfirmRerunRulesModal } from "../ConfirmRerunRulesModal";

export const ModalComponentsMap: { [key in ModalNames]: FunctionComponent } = {
  confirmCancelShipment: ConfirmDeleteShipmentModal,
  confirmCancelPickup: ConfirmCancelPickupModal,
  confirmBulkDeleteShipmentsModal: ConfirmBulkDeleteShipmentsModal,
  confirmRerunRulesModal: ConfirmRerunRulesModal,
  confirmCloseCreateShipmentModal: ConfirmCloseCreateShipmentModal,
  confirmDeleteShipmentProfile: ConfirmDeleteShipmentProfileModal,
  confirmRefetchShipmentRates: ConfirmRefetchShipmentRates,
  createBulkShipmentProfile: BulkShipmentProfileWizard,
  createBulkShipment: CreateBulkShipmentModal,
  createShipment: CreateShipmentModal,
  createSingleLabel: CreateSingleLabelModal,
  createCompanyAddress: CreateCompanyAddressModal,
  createReceiverAddress: CreateReceiverAddressModal,
  createQuotePalletSwitchWarningModal: CreateQuotePalletSwitchWarningModal,
  customerOnStopWarning: CustomerOnStopWarning,
  createRule: CreateRuleModal,
  editRule: EditRuleModal,
  editBulkShipmentProfile: EditBulkShipmentProfile,
  labels: LabelsDrawer,
  selectSenderAddress: SelectSenderAddressModal,
  selectReceiverAddress: SelectReceiverAddressModal,
  viewQuote: ViewQuoteModal,
  viewShipment: ViewShipmentModal,
  shipmentReport: ReportShipmentModal,
  quoteReport: ReportQuoteModal,
  helpContactInfo: ContactInfoModal,
  pickUpModal: PickupModal,
  pickUpRequestModal: PickUpRequestModal,
  viewPickupModal: ViewPickupModal,
};
