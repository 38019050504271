import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { IconType } from "react-icons";
import { Center } from "components/atoms/Center";
import { createElement } from "react";
import {
  getButtonBackgroundColor,
  getButtonBorderColor,
  getButtonHeight,
  getButtonTextColor,
  IButton,
} from "components/atoms/Button";
import styled from "styled-components";
import { useTheme } from "styled-components";
import cx from "classnames";

export type Props = {
  icon: IconType;
  itemWidth?: number;
  options?: { label: string; onClick?: () => void }[];
} & IButton;

const StyledButton = styled.button<IButton>`
  height: ${(props) => getButtonHeight(props.size)};
  width: ${(props) => getButtonHeight(props.size)};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  line-height: 1;
  color: ${(props) => getButtonTextColor(props.colorScheme)};
  background-color: ${(props) =>
    getButtonBackgroundColor(props.colorScheme, "default", props.variant)};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid;
  border-color: ${(props) =>
    getButtonBorderColor(props.colorScheme, "default", props.variant)};
  transition: all ${(props) => props.theme.transitionSpeedDefault};
  outline: none;

  :hover {
    background-color: ${(props) =>
      getButtonBackgroundColor(
        props.colorScheme,
        "hover",
        props.variant,
        props.hoverColorScheme
      )};
    border-color: ${(props) =>
      getButtonBorderColor(
        props.colorScheme,
        "hover",
        props.variant,
        props.hoverColorScheme
      )};
  }

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledDropdownMenuItem = styled.div`
  padding-left: ${(props) => props.theme.gutter};
  padding-right: ${(props) => props.theme.gutter};
  height: ${(props) => props.theme.space[10]};

  &:hover {
    background-color: ${(props) => props.theme.backgroundColorHover};
  }
`;

export const IconDropdown = (props: Props) => {
  const {
    id,
    isDisabled = false,
    isLoading = false,
    innerRef,
    className,
    variant = "solid",
    colorScheme = "default",
    testId,
    size = "default",
    icon,
    itemWidth = 300,
    options,
    ...rest
  } = props;

  const theme = useTheme();

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton>
          <StyledButton
            id={id}
            isLoading={isLoading}
            disabled={isLoading || isDisabled}
            className={className}
            type="button"
            size={size}
            variant={variant}
            colorScheme={colorScheme}
            {...(innerRef && { ref: innerRef })}
            {...(testId && { "data-testid": testId })}
            {...rest}
          >
            <Center>{createElement(icon, { size: 12 })}</Center>
          </StyledButton>
        </MenuButton>
      </div>

      <MenuItems
        transition
        style={{
          width: itemWidth,
          borderRadius: theme.borderRadius,
          backgroundColor: theme.dropdownBackgroundColor,
          borderColor: theme.dropdownBorderColor,
          boxShadow: theme.shadows["2xl"],
        }}
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in border"
      >
        {options?.map((option) => {
          return (
            <MenuItem>
              <div className="py-1.5">
                <StyledDropdownMenuItem
                  className={cx(
                    "leading-none flex items-center cursor-pointer text-sm",
                    className
                  )}
                  onClick={option.onClick}
                >
                  {option.label}
                </StyledDropdownMenuItem>
              </div>
            </MenuItem>
          );
        })}
      </MenuItems>
    </Menu>
  );
};
