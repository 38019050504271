import { FaDownload } from "react-icons/fa";
import { IconDropdown } from "components/molecules/TailwindsDropdowns/IconDropdown";

interface Props {
  exportData: () => void;
  downloadOptions?: { label: string; onClick: () => void }[];
}

export const TableControls = (props: Props) => {
  const { exportData, downloadOptions } = props;
  return (
    <>
      <IconDropdown
        icon={FaDownload}
        itemWidth={240}
        options={downloadOptions}
      />
    </>
  );
};
