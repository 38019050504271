import { AppThunk, RootState } from "store";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import {
  fetchPickups,
  fetchEligiblePickupShipments,
  fetchPickupAddresses,
  fetchShipmentsRelatedToPickups,
} from "services/api/shipments";
import { ShipmentStatusCategories } from "constants/shipment-statuses.config";
import {
  setAddressStatus,
  setShipmentStatus,
  setPickupsStatus,
  setShipmentsFromPickupsStatus,
  setAddressData,
  setShipmentData,
  setPickupsData,
  setShipmentsFromPickupsData,
} from "store/pickupSlice/index.slice";

export const fetchPickUpAddressesThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setAddressStatus(ApiLoadingStatus.LOADING));
    const {
      authSlice: { salesAccountUUID },
      pickupSlice,
    } = getState() as RootState;

    const {
      filters: { fromDate, toDate },
    } = pickupSlice.addressQuery;
    // fromDate should always be at 00:00:00 timestamp
    const adjustedFromDate = `${fromDate.split("T")[0]}T00:00:00.000Z`;
    // toDate should always be at 23:59:59 timestamp
    const adjustedToDate = `${toDate.split("T")[0]}T23:59:59.000Z`;
    if (salesAccountUUID) {
      const { data, error, meta } = await fetchPickupAddresses(
        salesAccountUUID,
        {
          filters: {
            ...(fromDate && { fromDate: adjustedFromDate }),
            ...(toDate && { toDate: adjustedToDate }),
          },
        }
      );
      await dispatch(setAddressStatus(ApiLoadingStatus.SUCCESS));

      if (!error) {
        dispatch(setAddressData({ data, meta }));
      }
    }
  };
};

export const fetchEligiblePickUpShipmentsThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setShipmentStatus(ApiLoadingStatus.LOADING));
    const {
      authSlice: { salesAccountUUID },
      pickupSlice: { addressQuery, addressData },
      tableSlice: { numberOfRowsSelected },
    } = getState() as RootState;

    const {
      offset,
      filters: { fromDate, toDate },
    } = addressQuery;
    // fromDate should always be at 00:00:00 timestamp
    const adjustedFromDate = `${fromDate.split("T")[0]}T00:00:00.000Z`;
    // toDate should always be at 23:59:59 timestamp
    const adjustedToDate = `${toDate.split("T")[0]}T23:59:59.000Z`;
    let selectedAddress = null;

    if (addressData.ups.some((upsAddress) => !upsAddress.hide)) {
      selectedAddress = addressData.ups.find((upsAddress) => !upsAddress.hide);
    } else if (addressData.fedex.some((fedexAddress) => !fedexAddress.hide)) {
      selectedAddress = addressData.fedex.find(
        (fedexAddress) => !fedexAddress.hide
      );
    }
    if (salesAccountUUID) {
      const { data, error, meta } = await fetchEligiblePickupShipments(
        salesAccountUUID,
        {
          offset,
          filters: {
            statuses: ShipmentStatusCategories.COMPLETE,
            ...(fromDate && { fromDate: adjustedFromDate }),
            ...(toDate && { toDate: adjustedToDate }),
            ...(selectedAddress?.fromAddressCity && {
              fromAddressCity: selectedAddress?.fromAddressCity,
            }),
            ...(selectedAddress?.fromAddressCountryIso && {
              fromAddressCountryIso: selectedAddress?.fromAddressCountryIso,
            }),
            ...(selectedAddress?.fromAddressStreet1 && {
              fromAddressStreet1: selectedAddress?.fromAddressStreet1,
            }),
            ...(selectedAddress?.fromAddressZip && {
              fromAddressZip: selectedAddress?.fromAddressZip,
            }),
            ...(selectedAddress?.carrierId && {
              carrierID: selectedAddress?.carrierId,
            }),
          },
          limit: numberOfRowsSelected.value,
        }
      );

      await dispatch(setShipmentStatus(ApiLoadingStatus.SUCCESS));

      if (!error) {
        dispatch(setShipmentData({ data, meta }));
      }
    }
  };
};

export const fetchPickupsThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setPickupsStatus(ApiLoadingStatus.LOADING));
    const {
      authSlice: { salesAccountUUID },
      pickupSlice,
      tableSlice: { numberOfRowsSelected },
    } = getState() as RootState;

    const {
      offset,
      filters: { search, fromDate, toDate },
      sort,
    } = pickupSlice.pickupsQuery;
    // fromDate should always be at 00:00:00 timestamp
    const adjustedFromDate = `${fromDate.split("T")[0]}T00:00:00.000Z`;
    // toDate should always be at 23:59:59 timestamp
    const adjustedToDate = `${toDate.split("T")[0]}T23:59:59.000Z`;
    if (salesAccountUUID) {
      const { data, error, meta } = await fetchPickups(salesAccountUUID, {
        offset,
        filters: {
          statuses: ShipmentStatusCategories.COMPLETE,
          ...(search && { search }),
          ...(fromDate && { fromDate: adjustedFromDate }),
          ...(toDate && { toDate: adjustedToDate }),
        },
        sort,
        limit: numberOfRowsSelected.value,
      });

      await dispatch(setPickupsStatus(ApiLoadingStatus.SUCCESS));
      if (!error) {
        dispatch(setPickupsData({ data, meta }));
      }
    }
  };
};

export const fetchShipmentsRelatedToPickupsThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setShipmentsFromPickupsStatus(ApiLoadingStatus.LOADING));
    const {
      authSlice: { salesAccountUUID },
      pickupSlice,
      tableSlice: { numberOfRowsSelected },
    } = getState() as RootState;

    const { offset, sort } = pickupSlice.shipmentsFromPickupsQuery;
    const { selectedShipment } = pickupSlice;
    const { shipmentIDs } = selectedShipment;
    if (salesAccountUUID) {
      const { data, error, meta } = await fetchShipmentsRelatedToPickups(
        salesAccountUUID,
        {
          offset,
          filters: {
            statuses: ShipmentStatusCategories.COMPLETE,
            shipmentIDs,
          },
          sort,
          limit: numberOfRowsSelected.value,
        }
      );

      await dispatch(setShipmentsFromPickupsStatus(ApiLoadingStatus.SUCCESS));
      if (!error) {
        dispatch(setShipmentsFromPickupsData({ data, meta }));
      }
    }
  };
};
