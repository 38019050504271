import { UNHANDLED_ERROR_MESSAGE } from "components/organisms/ShipmentErrors/ShipmentErrors.utils";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { QuerySort } from "interfaces/api.interface";
import { IShipment } from "models/shipment/shipment.model";
import { toast } from "react-hot-toast";
import {
  fetchShipments,
  fetchAllTrackingStatuses,
  fetchAllIntegrationStores,
} from "services/api/shipments";
import { AppThunk, RootState } from "store";
import {
  setData,
  setQueryBulkShipment,
  setQueryFromAddressCountryIso,
  setQueryFromDate,
  setQueryToDate,
  setQueryLabelJob,
  setQueryOffset,
  setQuerySearch,
  setQuerySort,
  setQueryToAddressCountryIso,
  setStatus,
  setQueryTrackingStatus,
  setTrackingStatuses,
  setAllIntegrationStores,
  setQueryIntegrationStoreId,
  setQuerySku,
  setQueryQuantity,
  setDateRangeDays,
} from "../index.slice";
import { IShipmentsSlice } from "../ShipmentsSlice.interface";
import { calculateDaysBetweenDates } from "utils/calculate/calculateDaysBetweenDates.util";
import dayjs from "dayjs";

export const searchShipmentsThunk = (query: string): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQuerySearch(query));

    dispatch(fetchShipmentsThunk());
  };
};

export const changePageThunk = (page: number): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQueryOffset(page));

    dispatch(fetchShipmentsThunk());
  };
};

export const changeSortOrderThunk = (sort: QuerySort<IShipment>): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQuerySort(sort));

    dispatch(fetchShipmentsThunk());
  };
};

export const changeBulkUploadFilterThunk = (
  bulkUpload: IShipmentsSlice["query"]["filters"]["bulkShipment"]
): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQueryBulkShipment(bulkUpload));

    dispatch(fetchShipmentsThunk());
  };
};

export const changeFromCountryIsoFilterThunk = (
  countryIso: IShipmentsSlice["query"]["filters"]["fromAddressCountryIso"]
): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQueryFromAddressCountryIso(countryIso));

    dispatch(fetchShipmentsThunk());
  };
};

export const changeTrackingStatusFilterThunk = (
  trackingStatus: IShipmentsSlice["query"]["filters"]["trackingStatus"]
): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQueryTrackingStatus(trackingStatus));
    dispatch(fetchShipmentsThunk());
  };
};

export const changeStoreNameFilterThunk = (
  integrationStoreId: IShipmentsSlice["query"]["filters"]["integrationStoreId"]
): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQueryIntegrationStoreId(integrationStoreId));
    dispatch(fetchShipmentsThunk());
  };
};

export const changeSkuFilterThunk = (
  sku: IShipmentsSlice["query"]["filters"]["sku"]
): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQuerySku(sku));
  };
};

export const changeQuantityFilterThunk = (
  quantity: IShipmentsSlice["query"]["filters"]["quantity"]
): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQueryQuantity(quantity));
  };
};

export const clearFiltersThunk = (): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQueryTrackingStatus(null));
    await dispatch(setQueryBulkShipment(null));
    await dispatch(setQueryFromAddressCountryIso(null));
    await dispatch(setQueryToAddressCountryIso(null));
    await dispatch(setQueryLabelJob(null));
    dispatch(fetchShipmentsThunk());
  };
};

export const changeDateRangeDaysThunk = (
  dateRangeDay: "1d" | "2d" | "7d" | "30d" | "1y" | "None"
): AppThunk => {
  return async (dispatch) => {
    dispatch(setDateRangeDays(dateRangeDay));
    let fromDate = new Date();
    const toDate = new Date();
    const isLeapYear = (year) => new Date(year, 1, 29).getMonth() === 1;
    if (dateRangeDay === "1d") {
      fromDate = new Date();
    } else if (dateRangeDay === "2d") {
      fromDate.setDate(fromDate.getDate() - 1);
    } else if (dateRangeDay === "7d") {
      fromDate.setDate(fromDate.getDate() - 7);
    } else if (dateRangeDay === "30d") {
      fromDate.setDate(fromDate.getDate() - 30);
    } else if (dateRangeDay === "1y") {
      fromDate.setDate(
        fromDate.getDate() - (isLeapYear(toDate.getFullYear()) ? 366 : 365)
      );
    }
    await dispatch(setQueryFromDate(fromDate.toISOString()));
    await dispatch(setQueryToDate(toDate.toISOString()));
    dispatch(fetchShipmentsThunk());
  };
};

export const changeDateRangeFilterThunk = (
  fromDate: IShipmentsSlice["query"]["filters"]["fromDate"],
  toDate: IShipmentsSlice["query"]["filters"]["toDate"]
): AppThunk => {
  return async (dispatch) => {
    let from = fromDate;
    let to = toDate;
    //prevents people from setting the from after the to
    if (toDate < fromDate) {
      from = toDate;
      to = fromDate;
    }
    await dispatch(setQueryFromDate(from));
    await dispatch(setQueryToDate(to));
    const daysBetweenDates = calculateDaysBetweenDates(
      new Date(from),
      new Date(to)
    );

    let dateRange: "1d" | "2d" | "7d" | "30d" | "1y" | "None" = "None";

    const isYesterday = () => {
      const today = new Date();

      // Set the date to yesterday by subtracting one day
      const yesterday = today.setDate(today.getDate() - 1);

      return (
        dayjs(new Date(yesterday)).format("DD/MM/YYYY") ===
        dayjs(new Date(from)).format("DD/MM/YYYY")
      );
    };

    if (daysBetweenDates === 0) {
      dateRange = "1d";
    } else if (daysBetweenDates === 1 && isYesterday()) {
      dateRange = "2d";
    } else if (daysBetweenDates === 7) {
      dateRange = "7d";
    } else if (daysBetweenDates === 30) {
      dateRange = "30d";
    }
    // else if (daysBetweenDates === (isLeapYear(new Date()) ? 366 : 365)) {
    //   dateRange = "1y";
    // }
    dispatch(fetchShipmentsThunk());
    dispatch(setDateRangeDays(dateRange));
  };
};

export const changeToCountryIsoFilterThunk = (
  countryIso: IShipmentsSlice["query"]["filters"]["toAddressCountryIso"]
): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQueryToAddressCountryIso(countryIso));

    dispatch(fetchShipmentsThunk());
  };
};

export const changeLabelJobFilterThunk = (
  labelJob: IShipmentsSlice["query"]["filters"]["labelJob"]
): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQueryLabelJob(labelJob));

    dispatch(fetchShipmentsThunk());
  };
};

export const fetchTrackingStatusesThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;
    const { data } = await fetchAllTrackingStatuses(salesAccountUUID);
    dispatch(setTrackingStatuses(data));
  };
};

export const fetchIntegrationStoresThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;
    const { data } = await fetchAllIntegrationStores(salesAccountUUID);
    dispatch(setAllIntegrationStores(data));
  };
};

export const fetchShipmentsThunk = (controller?: AbortController): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
      shipmentsSlice,
      tableSlice: { numberOfRowsSelected },
    } = getState() as RootState;
    await dispatch(fetchTrackingStatusesThunk());
    await dispatch(fetchIntegrationStoresThunk());
    dispatch(setStatus(ApiLoadingStatus.LOADING));
    const {
      offset,
      filters: {
        labelJob,
        bulkShipment,
        statuses,
        search,
        fromAddressCountryIso,
        toAddressCountryIso,
        fromDate,
        toDate,
        trackingStatus,
        integrationStoreId,
        sku,
        quantity,
      },
      sort,
    } = shipmentsSlice.query;

    // fromDate should always be at 00:00:00 timestamp
    const adjustedFromDate = `${fromDate.split("T")[0]}T00:00:00.000Z`;
    // toDate should always be at 23:59:59 timestamp
    const adjustedToDate = `${toDate.split("T")[0]}T23:59:59.000Z`;
    if (salesAccountUUID) {
      const response = await fetchShipments(
        salesAccountUUID,
        {
          offset,
          filters: {
            ...(statuses && { statuses }),
            ...(search && { search }),
            ...(bulkShipment && { bulkShipmentUUID: bulkShipment.uuid }),
            ...(labelJob && { labelJobUUID: labelJob.uuid }),
            ...(fromAddressCountryIso && { fromAddressCountryIso }),
            ...(toAddressCountryIso && { toAddressCountryIso }),
            ...(fromDate && { fromDate: adjustedFromDate }),
            ...(toDate && { toDate: adjustedToDate }),
            ...(trackingStatus && { trackingStatus }),
            ...(integrationStoreId && { integrationStoreId }),
            ...(sku && { sku }),
            ...(quantity && { quantity }),
          },
          sort,
          limit: numberOfRowsSelected.value,
        },
        controller
      );
      const { data, error, meta, message } = response;
      if (!error) {
        dispatch(
          setData({
            meta,
            data,
          })
        );
        dispatch(setStatus(ApiLoadingStatus.SUCCESS));
      } else {
        //@ts-ignore
        if (message?.name !== "AbortError") {
          toast.error(UNHANDLED_ERROR_MESSAGE);
        }
      }
    }
  };
};
