import { ReactNode } from "react";
import { DangerousGoodsSelect } from "components/molecules/DangerousGoodsSelect";
import {
  setDangerousGoodsOption,
  setPackages,
} from "store/createShipmentSlice/index.slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { isUserAdminForSalesAccounts } from "utils/isUserAdminForSalesAccounts";

export const NumberedSectionHeader = ({
  number,
  children,
  isQuote,
}: {
  number: number;
  children: ReactNode;
  isQuote: boolean;
}) => {
  const dispatch = useDispatch();

  const { dangerousGoodsOption, values, errors } = useSelector(
    (state: RootState) => {
      return state.createShipmentSlice;
    }
  );

  const { loggedInUser, salesAccountID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { items } = useSelector((state: RootState) => {
    return state.virtualWarehousesSlice;
  });

  const item = items.find((element) => element.isDefault) || null;

  const containsFedex = [];
  item?.whitelists.forEach((element) => {
    if (element.carrierId === "24") {
      containsFedex.push(element);
    }
  });

  return (
    <header className="flex mb-3  items-center">
      <div className="mr-3">
        <div className="inline-flex items-center justify-center text-sm font-bold leading-none text-white rounded-full w-7 h-7 bg-blue9">
          {number}
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div className="flex items-center text-lg font-semibold leading-none">
          {children}
        </div>
        {children === "Packages" &&
        !isQuote &&
        (isUserAdminForSalesAccounts(loggedInUser) ||
          salesAccountID.toString() === "225844") &&
        containsFedex.length ? (
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="flex mr-4 items-center">
                Dangerous Goods Category:
              </div>
              <div style={{ width: 300 }}>
                <DangerousGoodsSelect
                  value={dangerousGoodsOption}
                  onChange={(option) => {
                    dispatch(setDangerousGoodsOption(option));
                    const clone = values.items.map((item) => {
                      return {
                        ...item,
                        dgPackage: true,
                        lithiumCategory: "",
                        dryIceWeight: 0,
                        dgunNumber: 0,
                        class: "",
                        packingGroup: "",
                        dgNetWeight: 0,
                        dgVolumeMl100: 0,
                      };
                    });

                    dispatch(setPackages(clone));
                  }}
                />
              </div>
            </div>
            <div className="flex justify-end mt-1 text-sm text-red10">
              {errors.details?.dangerousGoods}
            </div>
          </div>
        ) : null}
      </div>
    </header>
  );
};
