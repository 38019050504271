import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { IButton, Button } from "components/atoms/Button";
import { Switch } from "components/atoms/Switch";
import styled from "styled-components";
import { useTheme } from "styled-components";
import cx from "classnames";
import { Column16 } from "@carbon/icons-react";
import { useDispatch } from "react-redux";
import { createElement, useEffect } from "react";
import { ColumnInstance, TableInstance } from "react-table";
import { TableCellIds } from "components/atoms/Table/types/index";
import { updateUserColumnThunk } from "store/authSlice";

const StyledDropdownMenuItem = styled.div`
  padding-left: ${(props) => props.theme.gutter};
  padding-right: ${(props) => props.theme.gutter};
  height: ${(props) => props.theme.space[10]};

  &:hover {
    background-color: ${(props) => props.theme.backgroundColorHover};
  }
`;

export const ColumnsDropdown = <T extends object>(props: TableInstance<T>) => {
  const {
    allColumns,
    setHiddenColumns,
    state: { hiddenColumns },
    userColumns,
  } = props;

  const dispatch = useDispatch();

  const currentUserHiddenColumns = userColumns
    ? Object.keys(userColumns).filter((key) => !userColumns[key])
    : [];
  // useEffect(() => {
  //   if (userColumns) {
  //     if (hiddenColumns !== currentUserHiddenColumns) {
  //       setHiddenColumns(currentUserHiddenColumns);
  //     }
  //   }
  // }, [userColumns]);

  const theme = useTheme();

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton>
          <Button>
            <Column16 className="mr-1" />
            Columns
          </Button>
        </MenuButton>
      </div>
      <MenuItems
        transition
        style={{
          width: 240,
          borderRadius: theme.borderRadius,
          backgroundColor: theme.dropdownBackgroundColor,
          borderColor: theme.dropdownBorderColor,
          boxShadow: theme.shadows["2xl"],
        }}
        className="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in border"
      >
        <div className="py-1.5">
          {" "}
          {allColumns.map((column: ColumnInstance<T>, i) => {
            if (
              column.id !== TableCellIds.CHECKBOX &&
              typeof column.Header === "string"
            ) {
              const cID = column.id === "Created by" ? "createdBy" : column.id;
              return (
                <StyledDropdownMenuItem
                  key={i}
                  className={cx(
                    "leading-none flex items-center cursor-pointer text-sm"
                  )}
                >
                  <div className="leading-none">
                    {column.icon && createElement(column.icon)}
                    {column.Header}
                  </div>
                  <div className="flex items-center ml-auto">
                    <Switch
                      checked={
                        userColumns
                          ? !currentUserHiddenColumns.includes(cID)
                          : !hiddenColumns.includes(cID)
                      }
                      onCheckedChange={(checked) => {
                        if (checked) {
                          if (userColumns) {
                            dispatch(
                              updateUserColumnThunk(
                                currentUserHiddenColumns.filter(
                                  (columnId) => columnId !== cID
                                ),
                                "shipmentsTable"
                              )
                            );
                          } else {
                            setHiddenColumns(
                              hiddenColumns.filter(
                                (columnId) => columnId !== cID
                              )
                            );
                          }
                        } else {
                          if (userColumns) {
                            dispatch(
                              updateUserColumnThunk(
                                [...currentUserHiddenColumns, cID],
                                "shipmentsTable"
                              )
                            );
                          } else {
                            setHiddenColumns([...hiddenColumns, cID]);
                          }
                        }
                      }}
                    />
                  </div>
                </StyledDropdownMenuItem>
              );
            }
          })}
        </div>
        {/* {options?.map((option) => {
          return (
            <MenuItem>
              <div className="py-1.5">
                <StyledDropdownMenuItem
                  className={cx(
                    "leading-none flex items-center cursor-pointer text-sm",
                    className
                  )}
                  onClick={option.onClick}
                >
                  {option.label}
                </StyledDropdownMenuItem>
              </div>
            </MenuItem>
          );
        })} */}
      </MenuItems>
    </Menu>
  );
};
