import {
  setAddressQueryFromDate,
  setAddressQueryToDate,
  setAddressDateRangeDays,
  setPickupsQueryFromDate,
  setPickupsQueryToDate,
  setPickupsDateRangeDays,
  IPickupSlice,
} from "store/pickupSlice/index.slice";
import { calculateDaysBetweenDates } from "utils/calculate/calculateDaysBetweenDates.util";
import {
  fetchPickUpAddressesThunk,
  fetchPickupsThunk,
} from "store/pickupSlice/thunks/fetchPickUpShipmentsThunk";
import { AppThunk } from "store";

export const changeCreateDateRangeFilterThunk = (
  fromDate: IPickupSlice["addressQuery"]["filters"]["fromDate"],
  toDate: IPickupSlice["addressQuery"]["filters"]["toDate"]
): AppThunk => {
  return async (dispatch) => {
    let from = fromDate;
    let to = toDate;
    //prevents people from setting the from after the to
    if (toDate < fromDate) {
      from = toDate;
      to = fromDate;
    }
    await dispatch(setAddressQueryFromDate(from));
    await dispatch(setAddressQueryToDate(to));
    const daysBetweenDates = calculateDaysBetweenDates(
      new Date(from),
      new Date(to)
    );

    let dateRange: "1d" | "7d" | "30d" | "1y" | "None" = "None";

    const isLeapYear = (year) => new Date(year, 1, 29).getMonth() === 1;

    if (daysBetweenDates === 0) {
      dateRange = "1d";
    } else if (daysBetweenDates === 7) {
      dateRange = "7d";
    } else if (daysBetweenDates === 30) {
      dateRange = "30d";
    } else if (daysBetweenDates === (isLeapYear(new Date()) ? 366 : 365)) {
      dateRange = "1y";
    }
    dispatch(fetchPickUpAddressesThunk());
    dispatch(setAddressDateRangeDays(dateRange));
  };
};

export const changeViewDateRangeFilterThunk = (
  fromDate: IPickupSlice["pickupsQuery"]["filters"]["fromDate"],
  toDate: IPickupSlice["pickupsQuery"]["filters"]["toDate"]
): AppThunk => {
  return async (dispatch) => {
    let from = fromDate;
    let to = toDate;
    //prevents people from setting the from after the to
    if (toDate < fromDate) {
      from = toDate;
      to = fromDate;
    }
    await dispatch(setPickupsQueryFromDate(from));
    await dispatch(setPickupsQueryToDate(to));
    const daysBetweenDates = calculateDaysBetweenDates(
      new Date(from),
      new Date(to)
    );

    let dateRange: "1d" | "7d" | "30d" | "1y" | "None" = "None";

    const isLeapYear = (year) => new Date(year, 1, 29).getMonth() === 1;

    if (daysBetweenDates === 0) {
      dateRange = "1d";
    } else if (daysBetweenDates === 7) {
      dateRange = "7d";
    } else if (daysBetweenDates === 30) {
      dateRange = "30d";
    } else if (daysBetweenDates === (isLeapYear(new Date()) ? 366 : 365)) {
      dateRange = "1y";
    }
    dispatch(fetchPickupsThunk());
    dispatch(setPickupsDateRangeDays(dateRange));
  };
};
