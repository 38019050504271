import { PackageTypes } from "enum/package-types.enum";
import { Meta } from "interfaces/api.interface";
import { DimensionUnits } from "interfaces/dimension-units.type";
import { WeightUnits } from "interfaces/weight-units.type";
import { IShipmentPackageGrouped } from "models/shipmentPackage/shipmentPackage.model";
import {
  convertKilosToGrams,
  convertValueToDatabase,
} from "utils/convertGramsToKilos";

export const MAX_PARCEL_METRIC_WEIGHT_GRAMS = convertValueToDatabase(
  convertKilosToGrams(70)
);

export const MAX_PACKAGE_HEIGHT_CM = convertValueToDatabase(270);

export const MAX_PACKAGE_GIRTH_CM = convertValueToDatabase(440);

export const MAX_ENVELOPPE_WEIGHT_GRAMS = convertValueToDatabase(
  convertKilosToGrams(0.5)
);

export const MAX_TOTAL_WEIGHT_BEFORE_FREIGHT_WARNING = convertValueToDatabase(
  convertKilosToGrams(150)
);

export const DEFAULT_PAGE_SIZE = 50;

export const DEFAULT_DIMENSIONS_UNIT = DimensionUnits.cm;

export const DEFAULT_WEIGHT_UNIT = WeightUnits.kg;

export const DEFAULT_MIN_HEIGHT_CM = convertValueToDatabase(0.1);

export const DEFAULT_MIN_WIDTH_CM = convertValueToDatabase(0.1);

export const DEFAULT_MIN_LENGTH_CM = convertValueToDatabase(0.1);

export const DEFAULT_MIN_WEIGHT_GRAMS = convertValueToDatabase(0.1);

export const DEFAULT_CURRENCY_ISO = "GBP";

export const DEFAULT_FROM_COUNTRY = "United Kingdom";

export const DEFAULT_FROM_COUNTRY_ISO_CODE = "GB";

export const GA_DEFAULT_FROM_COUNTRY_ISO_CODE = "US";

export const DEFAULT_PACKAGE_TYPE = PackageTypes.Parcel;

export const IRELAND_ISO_CODE = "IE";

export const DEFAULT_META: Meta = {
  recordCount: 0,
  limit: DEFAULT_PAGE_SIZE,
  offset: 0,
  pageCount: 0,
};

export const DEFAULT_PACKAGE: IShipmentPackageGrouped = {
  weight: 0,
  quantity: 1,
  width: 0,
  type: DEFAULT_PACKAGE_TYPE,
  length: 0,
  height: 0,
  lithiumCategory: "",
  dryIceWeight: 0,
  dgunNumber: 0,
  class: "",
  packingGroup: "",
  dgNetWeight: 0,
  dgPackage: false,
  dgVolumeMl100: 0,
};

export const ZIP_VALIDATION_WHITELIST = ["IE", "HK", "LB"];

export const FREIGHT_TEAM_EMAIL_ADDRESS = "freightquote@itdglobal.com";
