import * as yup from "yup";
import { ShipmentContentsFieldNames } from "enum/shipment-field-names.enum";
import { GenericErrorCodes } from "enum/error-codes/generic-error-codes.enum";

const REQUIRED_PARAM = "$isRequired";

export const CreateShipmentContentsValidationSchema = yup.object({
  [ShipmentContentsFieldNames.description]: yup
    .string()
    .when(REQUIRED_PARAM, (isRequired, field) =>
      !isRequired ? field : field.required(GenericErrorCodes.FIELD_REQUIRED)
    ),
  // [ShipmentContentsFieldNames.commodityCode]: yup
  //   .string()
  //   .when(REQUIRED_PARAM, (isRequired, field) =>
  //     !isRequired ? field : field.required(GenericErrorCodes.FIELD_REQUIRED)
  //   ),
  // [ShipmentContentsFieldNames.sku]: yup.string().nullable(),
  [ShipmentContentsFieldNames.quantity]: yup
    .number()
    .when(REQUIRED_PARAM, (isRequired, field) =>
      !isRequired ? field : field.required(GenericErrorCodes.FIELD_REQUIRED)
    ),
  [ShipmentContentsFieldNames.itemValue]: yup
    .number()
    .when(REQUIRED_PARAM, (isRequired, field) =>
      !isRequired ? field : field.moreThan(0)
    ),
  [ShipmentContentsFieldNames.currency]: yup
    .string()
    .when(REQUIRED_PARAM, (isRequired, field) =>
      !isRequired ? field : field.required(GenericErrorCodes.FIELD_REQUIRED)
    ),
  [ShipmentContentsFieldNames.weight]: yup
    .string()
    .when(REQUIRED_PARAM, (isRequired, field) =>
      !isRequired ? field : field.required(GenericErrorCodes.FIELD_REQUIRED)
    ),
  [ShipmentContentsFieldNames.countryOfOrigin]: yup
    .string()
    .when(REQUIRED_PARAM, (isRequired, field) =>
      !isRequired ? field : field.required(GenericErrorCodes.FIELD_REQUIRED)
    ),
});
