import { IconButton } from "components/atoms/IconButton";
import { Stack } from "components/atoms/Stack";
import { Tooltip } from "components/atoms/Tooltip";
import { IShipment } from "models/shipment/shipment.model";
import { ReactNode } from "react";
import { FaPrint, FaTimesCircle, FaRedo } from "react-icons/fa";
import { Portal } from "react-portal";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { motion } from "framer-motion";

export enum BulkActions {
  "DELETE" = "DELETE",
  "PRINT" = "PRINT",
  "RERUN" = "RERUN",
}

export const FadeInUp = ({
  children,
  delay = 0,
}: {
  children: ReactNode;
  delay?: number;
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.25, delay }}
    >
      {children}
    </motion.div>
  );
};

export const ShipmentsBulkActions = ({
  selectedShipmentIds,
  handleCreateLabelJob,
  handleDelete,
  handleRerunRules,
  actions = [],
}: {
  selectedShipmentIds: IShipment["id"][];
  handleCreateLabelJob(): void;
  handleDelete(): void;
  handleRerunRules(): void;
  actions: BulkActions[];
}) => {
  const { createLoadingStatus } = useSelector((state: RootState) => {
    return state.labelJobsSlice;
  });

  return (
    <Portal>
      <FadeInUp>
        <div
          className="fixed flex items-center h-12 pl-6 pr-2 text-white transform -translate-x-1/2 border rounded-md shadow-2xl left-1/2 bottom-20 border-gray5"
          style={{
            backgroundColor: "black",
          }}
        >
          <div className="mr-4 text-sm whitespace-nowrap">
            Selected {selectedShipmentIds.length} items
          </div>
          <Stack direction="horizontal" spacing={2}>
            {actions.includes(BulkActions.PRINT) && (
              <Tooltip content="Print">
                <IconButton
                  isDisabled={createLoadingStatus === "LOADING"}
                  icon={FaPrint}
                  onClick={handleCreateLabelJob}
                />
              </Tooltip>
            )}
            {actions.includes(BulkActions.DELETE) && (
              <Tooltip
                content={`Cancel ${selectedShipmentIds.length} shipment${
                  selectedShipmentIds.length > 1 ? "s" : ""
                }`}
              >
                <IconButton
                  hoverColorScheme="danger"
                  icon={FaTimesCircle}
                  onClick={handleDelete}
                  isDisabled={createLoadingStatus === "LOADING"}
                />
              </Tooltip>
            )}
            {actions.includes(BulkActions.RERUN) && (
              <Tooltip content={"Rerun rules"}>
                <IconButton
                  isDisabled={createLoadingStatus === "LOADING"}
                  icon={FaRedo}
                  onClick={handleRerunRules}
                />
              </Tooltip>
            )}
          </Stack>
        </div>
      </FadeInUp>
    </Portal>
  );
};
