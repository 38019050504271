import {
  CreateShipmentAddress,
  CreateShipmentAddressErrors,
  CreateShipmentAddressType,
  CreateShipmentContents,
  CreateShipmentContentsErrors,
  CreateShipmentDetails,
  CreateShipmentDetailsErrors,
  CreateShipmentErrors,
  CreateShipmentItemErrors,
  ICreateShipmentSlice,
} from "../createShipmentSlice.types";
import { ICountry } from "models/country/country.model";
import { IShipmentPackageGrouped } from "models/shipmentPackage/shipmentPackage.model";
import {
  DEFAULT_ADDRESS_ERRORS,
  DEFAULT_DETAILS_ERRORS,
  DEFAULT_ITEM_CONTENT_ERRORS,
  DEFAULT_PACKAGE_ERRORS,
} from "../CreateShipmentSliceDefaults.const";
import { isZipRequired } from "utils/zip/isZipRequired.util";
import { CreateShipmentDetailsValidationSchema } from "./CreateShipmentDetailsValidationSchema";
import { CreateShipmentPackageValidationSchema } from "./CreateShipmentPackagesValidationSchema";
import { CreateShipmentContentsValidationSchema } from "./CreateShipmentContentsValidationSchema";
import { CreateShipmentAddressValidationSchema } from "./CreateShipmentAddressValidationSchema";
import {
  isContentsFieldRequired,
  isContentsFieldRequiredParams,
} from "utils/isContentsFieldRequired";
import { PackageErrorCodes } from "enum/error-codes/package-error-codes.enum";

export class CreateShipmentValidator {
  countries: ICountry[];
  errors: CreateShipmentErrors;

  constructor({ countries }: { countries: ICountry[] }) {
    this.errors = {
      receiver: {
        ...DEFAULT_ADDRESS_ERRORS,
      },
      sender: {
        ...DEFAULT_ADDRESS_ERRORS,
      },
      contents: [{ ...DEFAULT_ITEM_CONTENT_ERRORS }],
      items: [{ ...DEFAULT_PACKAGE_ERRORS }],
      details: { ...DEFAULT_DETAILS_ERRORS },
    };

    this.countries = countries;
  }

  validateDetails(
    isInternational: boolean,
    details: CreateShipmentDetails
  ): {
    errors: CreateShipmentDetailsErrors;
    isValid: boolean;
  } {
    const errors: CreateShipmentDetailsErrors = {
      ...DEFAULT_DETAILS_ERRORS,
    };

    let isValid = true;

    try {
      CreateShipmentDetailsValidationSchema(isInternational).validateSync(
        details,
        {
          abortEarly: false,
          context: {
            isInternational,
          },
        }
      );
    } catch (err) {
      err.inner.forEach((e) => {
        errors[e.path] = e.message;
      });

      isValid = false;
    }

    return { errors, isValid };
  }

  validatePackages(
    packages: IShipmentPackageGrouped[],
    items: CreateShipmentContents[],
    dangerousGoodsOption?: ICreateShipmentSlice["dangerousGoodsOption"]
  ): {
    errors: CreateShipmentItemErrors[];
    isValid: boolean;
  } {
    const errors = [];

    let isValid = true;

    packages.map((p, i) => {
      const packageErrors = {};

      try {
        CreateShipmentPackageValidationSchema.validateSync(p, {
          abortEarly: false,
        });
      } catch (err) {
        err.inner.forEach((e) => {
          packageErrors[e.path] = e.message;
        });
      }

      // validate that item weight is not greater than package weight

      const packageWeight = p.weight;
      const packageID = i;

      const matchingItems = items.filter(
        (item) => item.packageId === packageID
      );

      const totalItemsWeight = matchingItems.reduce(
        (acc, item) => acc + item.weight * item.quantity,
        0
      );

      if (totalItemsWeight > packageWeight) {
        packageErrors["itemAndPackageWeight"] = `Package #${
          packageID + 1
        } weight is ${packageWeight / 100 / 1000}kg but total items weight is ${
          totalItemsWeight / 100 / 1000
        }kg`;
      }

      if (p.dgPackage) {
        switch (dangerousGoodsOption?.value) {
          case 0:
            if (!p.class) {
              packageErrors["class"] = PackageErrorCodes.CLASS_REQUIRED;
            }
            if (!p.packingGroup) {
              packageErrors["packingGroup"] =
                PackageErrorCodes.PACKING_GROUP_REQUIRED;
            }
            if (!p.dgNetWeight) {
              packageErrors["dgNetWeight"] =
                PackageErrorCodes.DG_NET_WEIGHT_REQUIRED;
            }
            if (!p.dgunNumber) {
              packageErrors["dgunNumber"] =
                PackageErrorCodes.DG_UN_NUMBER_REQUIRED;
            }
            if (
              !p.dgVolumeMl100 &&
              (p.class === "2.1" ||
                p.class === "2.2" ||
                p.class === "2.3" ||
                p.class === "3")
            ) {
              packageErrors["dgVolumeMl100"] =
                PackageErrorCodes.DG_VOLUME_REQUIRED;
            }

            break;

          case 1:
            if (!p.dryIceWeight) {
              packageErrors["dryIceWeight"] =
                PackageErrorCodes.DRY_ICE_WEIGHT_REQUIRED;
            }
            break;

          case 2:
            if (!p.lithiumCategory) {
              packageErrors["lithiumCategory"] =
                PackageErrorCodes.LITHIUM_CATEGORY_REQUIRED;
            }
            break;

          default:
            break;
        }
      }

      errors.push(packageErrors);
    });
    errors.forEach((element) => {
      if (Object.keys(element).length) {
        isValid = false;
      }
    });

    return { errors, isValid };
  }

  validatePackageAndItemsWeight(
    packages: IShipmentPackageGrouped[],
    items: CreateShipmentContents[]
  ): {
    errors: { itemAndPackageWeight: string }[];
    isValid: boolean;
  } {
    const errors = [];

    let isValid = true;

    packages.forEach((P, i) => {
      // validate that item weight is not greater than package weight

      const packageWeight = P.weight;
      const packageID = i;

      const packageErrors = {};

      const matchingItems = items.filter(
        (item) => item.packageId === packageID
      );

      const totalItemsWeight = matchingItems.reduce(
        (acc, item) => acc + item.weight,
        0
      );

      if (totalItemsWeight > packageWeight) {
        isValid = false;
        packageErrors["itemAndPackageWeight"] = `Package ${
          packageID + 1
        } weight is ${packageWeight} but total items weight is ${totalItemsWeight}}`;
      }

      errors.push(packageErrors);
    });

    return { errors, isValid };
  }

  validateContents(
    params: {
      items: CreateShipmentContents[];
    } & isContentsFieldRequiredParams
  ): { isValid: boolean; errors: CreateShipmentContentsErrors[] } {
    const { items, fromCountryIso, toCountryIso, toCountryZip } = params;

    const errors = [];

    let isValid = true;

    items.forEach((item) => {
      const itemErrors = {};

      try {
        CreateShipmentContentsValidationSchema.validateSync(item, {
          abortEarly: false,
          context: {
            isRequired: isContentsFieldRequired({
              fromCountryIso,
              toCountryIso,
              toCountryZip,
            }),
          },
        });
      } catch (err) {
        err.inner.forEach((e) => {
          itemErrors[e.path] = e.message;
        });

        isValid = false;
      }

      errors.push(itemErrors);
    });

    return { errors, isValid };
  }

  validateAddress(
    direction: "from" | "to",
    address: CreateShipmentAddress
  ): {
    errors: CreateShipmentAddressErrors;
    isValid: boolean;
  } {
    let isValid = true;

    const errors: CreateShipmentAddressErrors = {
      ...DEFAULT_ADDRESS_ERRORS,
    };

    if (
      address.type === CreateShipmentAddressType.ADDRESS_BOOK &&
      !address.id
    ) {
      isValid = false;
      errors.id = "Please select an address.";
    }

    if (address.type === CreateShipmentAddressType.NEW) {
      const { countryIsoCode } = address;

      try {
        CreateShipmentAddressValidationSchema.validateSync(address, {
          abortEarly: false,
          context: {
            direction,
            isZipRequired: isZipRequired(countryIsoCode),
          },
        });
      } catch (e) {
        isValid = false;

        e.inner &&
          e.inner.forEach((e) => {
            errors[e.path] = e.message;
          });
      }
    }

    return { isValid, errors };
  }

  validateQuote(values: ICreateShipmentSlice["values"]): {
    errors: CreateShipmentErrors;
    isValid: boolean;
  } {
    const { items } = values;

    const { errors: itemErrors, isValid: isItemsValid } = this.validatePackages(
      items,
      []
    );

    const { errors: senderErrors } = this.validateAddress("from", {
      ...values.sender,
      type: CreateShipmentAddressType.NEW,
    });

    const { errors: receiverErrors } = this.validateAddress("to", {
      ...values.receiver,
      type: CreateShipmentAddressType.NEW,
    });

    return {
      errors: {
        sender: senderErrors,
        receiver: receiverErrors,
        items: itemErrors,
        contents: [],
        details: { ...DEFAULT_DETAILS_ERRORS },
      },
      isValid: isItemsValid,
    };
  }

  validateFetchRates(
    values: ICreateShipmentSlice["values"],
    dangerousGoodsOption?: ICreateShipmentSlice["dangerousGoodsOption"],
    carrierName?: string
  ): {
    errors: CreateShipmentErrors;
    isValid: boolean;
  } {
    const { sender, receiver, items, details, contents } = values;

    const { errors: fromErrors, isValid: isFromValid } = this.validateAddress(
      "from",
      sender
    );

    const { errors: toErrors, isValid: isToValid } = this.validateAddress(
      "to",
      receiver
    );

    const { errors: itemErrors, isValid: isItemsValid } = this.validatePackages(
      items,
      contents,
      dangerousGoodsOption
    );

    let noRestrictedPackages = false;
    if (dangerousGoodsOption) {
      noRestrictedPackages = !items.some((element) => element.dgPackage);
    }
    const isDetailsValid = !noRestrictedPackages || carrierName === "amazon";
    // const isInternational = sender.countryIsoCode !== receiver.countryIsoCode;

    // const { errors: detailsErrors, isValid: isDetailsValid } =
    //   this.validateDetails(isInternational, details);

    // const {
    //   errors: itemAndPackgeWeightErrors,
    //   isValid: isItemAndPackageWeightValid,
    // } = this.validatePackageAndItemsWeight(items, contents);

    const { errors: contentsErrors, isValid: isContentsValid } =
      this.validateContents({
        fromCountryIso: sender.countryIsoCode,
        toCountryIso: receiver.countryIsoCode,
        toCountryZip: receiver.zip,
        items: contents,
      });

    return {
      errors: {
        sender: fromErrors,
        receiver: toErrors,
        items: itemErrors,
        contents: contentsErrors,
        details: {
          ...DEFAULT_DETAILS_ERRORS,
          dangerousGoods:
            noRestrictedPackages && carrierName !== "amazon"
              ? "Atleast 1 package must contain dg details."
              : "",
        },
      },
      isValid:
        isFromValid &&
        isToValid &&
        isItemsValid &&
        isContentsValid &&
        isDetailsValid,
    };
  }

  validateAll(
    values: ICreateShipmentSlice["values"],
    dangerousGoodsOption: ICreateShipmentSlice["dangerousGoodsOption"],
    carrierName: string
  ): {
    errors: CreateShipmentErrors;
    isValid: boolean;
  } {
    const { errors, isValid } = this.validateFetchRates(
      values,
      dangerousGoodsOption,
      carrierName
    );

    return { errors, isValid };
  }
}
