import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { changeDateRangeDaysThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";

export const NumberOfDaysComponent = ({
  todayOnChange,
  yesterdayOnChange,
  sevenDayOnChange,
  monthOnchange,
  yearOnChange,
  location,
}: {
  todayOnChange: Function;
  yesterdayOnChange?: Function;
  sevenDayOnChange: Function;
  monthOnchange: Function;
  yearOnChange: Function;
  location: "shipments" | "createPickups" | "viewPickups";
}) => {
  const dispatch = useDispatch();

  const { addressDateRangeDays, pickupsDateRangeDays } = useSelector(
    (state: RootState) => {
      return state.pickupSlice;
    }
  );

  const { dateRangeDays } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  let dateRange = "";
  if (location === "shipments") {
    dateRange = dateRangeDays;
  } else if (location === "createPickups") {
    dateRange = addressDateRangeDays;
  } else if (location === "viewPickups") {
    dateRange = pickupsDateRangeDays;
  }

  return (
    <div
      className="flex flex-row w-full justify-between text-sm"
      style={{ width: "90%" }}
    >
      <div
        className={`border-2 rounded-md px-1 py-1 cursor-pointer hover:underline ${
          dateRange === "1d"
            ? "border-blue10 text-blue10"
            : "text-gray9 border-transparent"
        } `}
        onClick={() => {
          todayOnChange();
        }}
      >
        Today
      </div>

      <div
        className={`border-2 rounded-md px-1 py-1 cursor-pointer hover:underline ${
          dateRange === "2d"
            ? "border-blue10 text-blue10"
            : "text-gray9 border-transparent"
        } `}
        onClick={() => {
          yesterdayOnChange();
        }}
      >
        Yesterday
      </div>
      <div
        className={`border-2 rounded-md px-1 py-1 cursor-pointer hover:underline ${
          dateRange === "7d"
            ? "border-blue10 text-blue10"
            : "text-gray9 border-transparent"
        } `}
        onClick={() => {
          sevenDayOnChange();
        }}
      >
        7d
      </div>
      <div
        className={`border-2 rounded-md px-1 py-1 cursor-pointer hover:underline ${
          dateRange === "30d"
            ? "border-blue10 text-blue10"
            : "text-gray9 border-transparent"
        } `}
        onClick={() => {
          monthOnchange();
        }}
      >
        30d
      </div>
      {/* <div
        className={`border-2 rounded-md px-2 py-1 cursor-pointer hover:underline ${
          dateRange === "1y"
            ? "border-blue10 text-blue10"
            : "text-gray9 border-transparent"
        } `}
        onClick={() => {
          yearOnChange();
        }}
      >
        1y
      </div> */}
    </div>
  );
};
