import {
  ValidatedBulkUploadObject,
  ValidatedBulkUploadObjectFlat,
} from "interfaces/validated-bulk-upload-object.interface";

export const flattenObjectToItems = (
  shipments: ValidatedBulkUploadObject
): ValidatedBulkUploadObjectFlat[] => {
  const flatShipments: ValidatedBulkUploadObjectFlat[] = [];

  shipments.packages.forEach((p, pi) => {
    // the first package item is the package itself
    // the rest of the items don't need the other package data
    // flatten packages
    // the first package is the package itself
    //the rest of the packages don't need other shipment data

    p.packageItems.forEach((itemObject, i) => {
      if (i === 0 && pi === 0) {
        flatShipments.push({
          ...p,
          ...shipments,
          ...itemObject,
        });
      } else {
        flatShipments.push({
          ...p,
          ...itemObject,
          warehouseName: "",
          customerReference: "",
          customerReference2: "",
          //@ts-expect-error needs to be empty for formatting
          reasonForExport: "",
          fromAddressFirstName: "",
          fromAddressLastName: "",
          fromAddressCompany: "",
          fromAddressPhone: "",
          fromAddressEmail: "",
          fromAddressStreet1: "",
          fromAddressStreet2: "",
          fromAddressStreet3: "",
          fromAddressCity: "",
          fromAddressCountyState: "",
          fromAddressZip: "",
          fromAddressCountryIso: "",
          fromAddressEoriNumber: "",
          fromAddressVatNumber: "",
          toAddressFirstName: "",
          toAddressLastName: "",
          toAddressCompany: "",
          toAddressPhone: "",
          toAddressEmail: "",
          toAddressStreet1: "",
          toAddressStreet2: "",
          toAddressStreet3: "",
          toAddressCity: "",
          toAddressCountyState: "",
          toAddressZip: "",
          toAddressCountryIso: "",
          toAddressEoriNumber: "",
          toAddressVatNumber: "",
          dangerousGoodsClass: null,
          dangerousGoodsPackingGroup: null,
          dangerousGoodsNetWeight: 0,
          unNumber: "",
          dryIceWeight: 0,
          lithiumCategory: null,
          dangerousGoodsVolume: 0,
          errors: shipments.errors,
          isValid: shipments.isValid,
          lineNumber: shipments.lineNumber,
        });
      }
    });
  });

  // shipments.forEach((shipment) => {
  //     shipment.items.forEach((item) => {
  //     flatShipments.push({
  //         ...shipment,
  //         items: [item],
  //     });
  //     });
  // });

  return flatShipments;
};

export const flattenAll = (
  shipments: ValidatedBulkUploadObject[]
): ValidatedBulkUploadObjectFlat[] => {
  const flatShipments: ValidatedBulkUploadObjectFlat[] = [];

  shipments.forEach((shipment) => {
    flatShipments.push(...flattenObjectToItems(shipment));
  });

  return flatShipments;
};
