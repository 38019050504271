import { AppThunk, RootState } from "store";
import { closeModal, openModal } from "store/modalSlice";
import { groupedItems } from "utils/groupItems";
import { CreateShipmentAddressType } from "../createShipmentSlice.types";
import {
  setEditingJobNumber,
  setEditingUUID,
  setEditingID,
  setValues,
  setDuplicateOrEdit,
} from "../index.slice";

export const editShipmentThunk = (duplicateOrEdit: string): AppThunk => {
  return async (dispatch, getState) => {
    const {
      shipmentsSlice: { currentShipment },
    } = getState() as RootState;

    console.log("currentShipment", currentShipment);
    if (currentShipment) {
      dispatch(setDuplicateOrEdit(duplicateOrEdit));
      dispatch(openModal({ name: "createShipment" }));
      dispatch(closeModal({ modalName: "viewShipment" }));
      dispatch(setEditingUUID(currentShipment.uuid));
      dispatch(setEditingID(currentShipment.id));
      dispatch(setEditingJobNumber(currentShipment.jobNumber));
      dispatch(
        setValues({
          sender: {
            saveToAddressBook: false,
            id: null,
            firstName: currentShipment.fromAddressFirstName || "",
            lastName: currentShipment.fromAddressLastName || "",
            email: currentShipment.fromAddressEmail || "",
            phone: currentShipment.fromAddressPhone || "",
            company: currentShipment.fromAddressCompany || "",
            addressLine1: currentShipment.fromAddressStreet1 || "",
            addressLine2: currentShipment.fromAddressStreet2 || "",
            addressLine3: currentShipment.fromAddressStreet3 || "",
            city: currentShipment.fromAddressCity || "",
            countyState: currentShipment.fromAddressCountyState || "",
            zip: currentShipment.fromAddressZip || "",
            countryIsoCode: currentShipment.fromAddressCountryIso,
            type: CreateShipmentAddressType.NEW,
            eoriNumber: currentShipment.fromAddressEoriNumber || "",
            vatNumber: currentShipment.fromAddressVatNumber || "",
          },
          receiver: {
            saveToAddressBook: false,
            id: null,
            firstName: currentShipment.toAddressFirstName || "",
            lastName: currentShipment.toAddressLastName || "",
            email: currentShipment.toAddressEmail || "",
            phone: currentShipment.toAddressPhone || "",
            company: currentShipment.toAddressCompany || "",
            addressLine1: currentShipment.toAddressStreet1 || "",
            addressLine2: currentShipment.toAddressStreet2 || "",
            addressLine3: currentShipment.toAddressStreet3 || "",
            city: currentShipment.toAddressCity || "",
            countyState: currentShipment.toAddressCountyState || "",
            zip: currentShipment.toAddressZip || "",
            countryIsoCode: currentShipment.toAddressCountryIso,
            type: CreateShipmentAddressType.NEW,
            eoriNumber: currentShipment.toAddressEoriNumber || "",
            vatNumber: currentShipment.toAddressVatNumber || "",
          },
          contents: currentShipment.itemDescriptions.map((item) => {
            return {
              packageId: item.shipmentPackageIndex,
              commodityCode: item.commodityCode,
              currency: item.currency,
              description: item.description,
              itemValue: item.itemValue,
              weight: item.weight,
              manufacturerDetails: item.manufacturerDetails,
              countryOfOrigin: item.countryOfOrigin,
              quantity: item.quantity,
              sku: item.sku,
            };
          }),
          // items: groupedItems(currentShipment.packages),
          items: currentShipment.packages.map((item) => {
            return {
              quantity: 1,
              type: item.type,
              length: item.length,
              width: item.width,
              height: item.height,
              weight: item.weight,
              lithiumCategory: item.lithiumCategory ? item.lithiumCategory : "",
              dryIceWeight: item.dryIceWeight ? item.dryIceWeight : 0,
              dgunNumber: item.dgunNumber ? item.dgunNumber : 0,
              class: item.class ? item.class : "",
              packingGroup: item.packingGroup ? item.packingGroup : "",
              dgNetWeight: item.dgNetWeight ? item.dgNetWeight : 0,
              dgVolumeMl100: item.dgVolumeMl100 ? item.dgVolumeMl100 : 0,
              dgPackage: item.dgPackage ? item.dgPackage : false,
            };
          }),
          details: {
            customerReference: currentShipment.customerReference,
            customerReference2: currentShipment.customerReference2,
            dutyOrTaxPaidBy: currentShipment.dutyOrTaxPaidBy,
            reasonForExport: currentShipment.reasonForExport,
            invoiceNumber: currentShipment.invoiceNumber,
            iossNumber: currentShipment.iossNumber,
            dangerousGoods: currentShipment.dangerousGoods,
            shipmentSourceName:
              duplicateOrEdit === "EDIT"
                ? currentShipment.shipmentSourceName
                : "manual creation",
            shouldSplitDutyAndVat: currentShipment.shouldSplitDutyAndVat,
            integrationStoreID:
              duplicateOrEdit === "EDIT"
                ? currentShipment.integrationStoreId
                : null,
            orderNumber: currentShipment.orderNumber,
            shipmentIntegrationID:
              duplicateOrEdit === "EDIT"
                ? currentShipment.shipmentIntegrationId
                : null,
          },
        })
      );
    }
  };
};
