import { DEFAULT_PAGE_SIZE } from "constants/misc";
import { Query } from "interfaces/api.interface";
import { fetcher, IFetchResponseSingle, IFetchResponsePaginated } from "./api";

export async function fetchSingle<T>(
  url: string
): Promise<IFetchResponseSingle<T>> {
  const { error, message, ...rest } = await fetcher<T>({
    url,
    method: "GET",
  });

  return {
    error,
    message,
    ...rest,
  };
}

export async function fetchAll<T, M>(
  url: string,
  query: Query = {},
  controller?: AbortController
): Promise<IFetchResponseSingle<T> | IFetchResponsePaginated<T, M>> {
  const DEFAULTS = {
    limit: DEFAULT_PAGE_SIZE,
    offset: 0,
  };

  const { error, message, ...rest } = await fetcher<T>({
    url,
    query: {
      ...DEFAULTS,
      ...query,
    },
    method: "GET",
    controller,
  });

  return {
    error,
    message,
    ...rest,
  };
}
