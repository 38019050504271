export enum PackageErrorCodes {
  "PACKAGE_WEIGHT_EXCEEDS_LIMIT" = "PACKAGE_WEIGHT_EXCEEDS_LIMIT",
  "PACKAGE_LENGTH_EXCEEDS_LIMIT" = "PACKAGE_LENGTH_EXCEEDS_LIMIT",
  "PACKAGE_GIRTH_EXCEEDS_LIMIT" = "PACKAGE_GIRTH_EXCEEDS_LIMIT",
  "PACKAGE_DIMENSIONS_EXCEEDS_LIMIT" = "PACKAGE_DIMENSIONS_EXCEEDS_LIMIT",
  "PACKAGE_MINIMUM_LENGTH_NOT_MET" = "PACKAGE_MINIMUM_LENGTH_NOT_MET",
  "PACKAGE_MINIMUM_HEIGHT_NOT_MET" = "PACKAGE_MINIMUM_HEIGHT_NOT_MET",
  "PACKAGE_MINIMUM_WIDTH_NOT_MET" = "PACKAGE_MINIMUM_WIDTH_NOT_MET",
  "PACKAGE_MINIMUM_WEIGHT_NOT_MET" = "PACKAGE_MINIMUM_WEIGHT_NOT_MET",
  "ITEM_WEIGHT_EXCEEDS_PACKAGE_WEIGHT" = "ITEM_WEIGHT_EXCEEDS_PACKAGE_WEIGHT",
  "CLASS_REQUIRED" = "CLASS_REQUIRED",
  "PACKING_GROUP_REQUIRED" = "PACKING_GROUP_REQUIRED",
  "DG_NET_WEIGHT_REQUIRED" = "DG_NET_WEIGHT_REQUIRED",
  "DG_UN_NUMBER_REQUIRED" = "DG_UN_NUMBER_REQUIRED",
  "DRY_ICE_WEIGHT_REQUIRED" = "DRY_ICE_WEIGHT_REQUIRED",
  "LITHIUM_CATEGORY_REQUIRED" = "LITHIUM_CATEGORY_REQUIRED",
  "DG_VOLUME_REQUIRED" = "DG_VOLUME_REQUIRED",
}
