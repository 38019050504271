/* eslint-disable @typescript-eslint/no-explicit-any */
import { FetchShipmentsResponseDto } from "./dtos/fetch-shipments.response.dto";
import { IShipment } from "models/shipment/shipment.model";
import {
  DropDropOffLocationsResponseDto,
  DropDropOffLocationsResponseDtoUPS,
  FetchShipmentResponseDto,
} from "./dtos/fetch-shipment.response.dto";
import { DownloadShipmentRequestDto } from "./dtos/download-shipment-request.dto";
import { IFetchPostResponse, IFetchResponseSingle } from "services/api";
import { fetchAll, fetchSingle } from "services/get";
import { apiPaths, customerApiBase } from "enum/paths.enum";
import { CreateShipmentRequestDto } from "./dtos/create-shipment.request.dto";
import { post } from "services/post";
import { patch } from "services/patch";
import { BulkCancelShipmentsRequestDto } from "./dtos/bulk-cancel-shipments.request.dto";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { del } from "services/del";
import { IQuotedRate } from "models/quote/quote.model";
import { FetchShipmentsQuery } from "./dtos/fetch-shipments-query";
import { CreateAddress } from "store/createShipmentSlice/createShipmentSlice.types";
import { PickupShipmentRequestDto } from "./dtos/pickup-shipment.request.dto";

export const fetchAllTrackingStatuses = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<IFetchPostResponse<string[]>> => {
  return await fetchAll(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/allTrackingStatuses"
  );
};

export const fetchAllIntegrationStores = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<IFetchPostResponse<{ id: string; identifier: string }[]>> => {
  return await fetchAll(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/allIntegrationStores"
  );
};

export const fetchAllSkus = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<IFetchPostResponse<{ id: string; identifier: string }[]>> => {
  return await fetchAll(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/allIntegrationStores"
  );
};

export const downloadShipmentReport = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values?: DownloadShipmentRequestDto
): Promise<IFetchPostResponse<string>> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/downloadShipmentReport",
    values
  );
};

export const createPickup = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values?: PickupShipmentRequestDto
): Promise<IFetchPostResponse<string>> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/createPickup",
    values
  );
};

export const cancelPickup = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values?: PickupShipmentRequestDto
): Promise<IFetchPostResponse<string>> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/cancelPickup",
    values
  );
};

export const fetchEligiblePickupShipments = async (
  salesAccountUUID: ISalesAccount["uuid"],
  query?: FetchShipmentsQuery
): Promise<any> => {
  return await fetchAll(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/fetchEligiblePickup",
    query
  );
};

export const fetchPickupAddresses = async (
  salesAccountUUID: ISalesAccount["uuid"],
  query?: FetchShipmentsQuery
): Promise<any> => {
  return await fetchAll(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/fetchPickupAddresses",
    query
  );
};

export const fetchPickups = async (
  salesAccountUUID: ISalesAccount["uuid"],
  query?: FetchShipmentsQuery
): Promise<FetchShipmentsResponseDto> => {
  return await fetchAll(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/fetchPickups",
    query
  );
};

export const fetchShipmentsRelatedToPickups = async (
  salesAccountUUID: ISalesAccount["uuid"],
  query?: FetchShipmentsQuery
): Promise<FetchShipmentsResponseDto> => {
  return await fetchAll(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/fetchShipmentsRelatedToPickup",
    query
  );
};

export const fetchShipments = async (
  salesAccountUUID: ISalesAccount["uuid"],
  query?: FetchShipmentsQuery,
  controller?: AbortController
): Promise<FetchShipmentsResponseDto> => {
  return await fetchAll(
    customerApiBase + "/" + salesAccountUUID + apiPaths.shipments,
    {
      ...query,
      filters: { ...query.filters, sku: encodeURIComponent(query.filters.sku) },
    },
    controller
  );
};

export const fetchShipment = async (
  salesAccountUUID: ISalesAccount["uuid"],
  shipmentUUID: IShipment["uuid"]
): Promise<IFetchResponseSingle<FetchShipmentResponseDto>> => {
  return await fetchSingle(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/" +
      shipmentUUID
  );
};

export const createShipment = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: CreateShipmentRequestDto
): Promise<IFetchPostResponse<FetchShipmentResponseDto>> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.shipments,
    values
  );
};

export const updateShipment = async (
  salesAccountUUID: ISalesAccount["uuid"],
  shipmentUUID: IShipment["uuid"],
  values: {
    createShipmentRequest: CreateShipmentRequestDto;
    duplicateOrEdit: string;
  }
): Promise<IFetchPostResponse<FetchShipmentResponseDto>> => {
  return await patch(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/" +
      shipmentUUID,
    values
  );
};

export const updateShipmentRate = async (
  salesAccountUUID: ISalesAccount["uuid"],
  shipmentUUID: IShipment["uuid"],
  values: {
    rateUUID: IQuotedRate["uuid"];
  }
): Promise<IFetchPostResponse<FetchShipmentResponseDto>> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/" +
      shipmentUUID +
      "/update-rate",
    values
  );
};
export const fetchShipmentDropOffLocations = async (
  values: CreateAddress,
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<IFetchResponseSingle<DropDropOffLocationsResponseDto>> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/dropOffLocations",
    values
  );
};
export const fetchShipmentDropOffLocationsUPS = async (
  values: CreateAddress,
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<IFetchResponseSingle<DropDropOffLocationsResponseDtoUPS>> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/dropOffLocationsUPS",
    values
  );
};
export const deleteShipment = async (
  salesAccountUUID: ISalesAccount["uuid"],
  shipmentUUID: IShipment["uuid"]
): Promise<IFetchPostResponse<FetchShipmentResponseDto>> => {
  return await del(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipments +
      "/" +
      shipmentUUID
  );
};

export const bulkCancelShipments = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: BulkCancelShipmentsRequestDto
): Promise<IFetchPostResponse<any>> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.bulkCancelShipments,
    values
  );
};

export const rerunRulesShipments = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: {
    shipmentIds: IShipment["id"][];
  }
): Promise<IFetchPostResponse<any>> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.rerunShipmentRules,
    values
  );
};
